import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { isEmpty } from '@helper/helper'
import { TabStore } from '@stores/tab.store'
@Component({
	selector: 'app-custom-table',
	templateUrl: './custom-table.component.html',
	styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, OnDestroy {
	tabs: any = []
	selectedTab: string | null = 'all'
	hoveredTab: string | null

	@Input() title: any = null
	@Input() data: any = null
	@Input() totalData: number
	@Input() pageSize: number
	@Input() page: number = 1
	@Input() isLoading: boolean = true
	@Input() borderRadiusCurve: boolean = false
	@Input() from: number
	@Input() to: number
	@Input() noHover: boolean = false
	@Input() headers: any
	@Input() flexible: boolean = false
	@Input() noMinHeight: boolean = false
	@Input() noPageSize: boolean = false
	@Input() key: string
	@Input() tabable: boolean = false

	@Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>()
	@Output() pageChange: EventEmitter<number> = new EventEmitter<number>()
	@Output() sort: EventEmitter<any> = new EventEmitter<any>()
	@Output() clickTab: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('editInput') editInput: ElementRef

	constructor(
		private cdr: ChangeDetectorRef,
		private tabStore: TabStore,
		private route: ActivatedRoute
	) {
		this.tabStore.data$.subscribe((data) => {
			if (!this.tabable) {
				return
			}

			if (!data?.tabs.length) {
				return
			}

			this.tabs = data.tabs

			this.cdr.detectChanges()
		})

		this.tabStore.selectedTab$.subscribe((key) => {
			// this.selectTab(key)
		})
	}

	ngOnChanges(data: SimpleChanges) {}

	ngOnInit() {
		this.tabs = []
		if (this.key && this.tabable) {
			this.tabStore.initialize(this.key)
		}
		this.headers = this.headers.filter((item: any) => item?.header !== false)
	}

	ngOnDestroy(): void {
		this.tabStore.destroy()
	}

	isArray() {
		return Array.isArray(this.data)
	}

	updatePageSize(e: any) {
		if (this.isLoading) {
			return
		}
		// this.pageSize = e.value
		this.pageSizeChange.emit(this.pageSize)
	}

	updatePage() {
		if (this.isLoading) {
			return
		}
		this.pageChange.emit(this.page)
	}

	updateSorting(index: any) {
		if (this.isLoading == true || this.headers[index].sort === false) {
			return
		}

		this.headers
			.filter((v: any, k: any) => k !== index && v.sort !== false)
			.forEach((v: any) => {
				v.sort = ''
			})

		if (this.headers[index].sort == 'asc') {
			this.headers[index].sort = 'desc'
		} else {
			this.headers[index].sort = 'asc'
		}

		if (this.headers[index].value) {
			this.sort.emit({ sortKey: this.headers[index].value, sortValue: this.headers[index].sort })
		}
	}

	addTab() {
		this.tabStore.addTab()
	}

	selectTab(tabKey: string) {
		if (this.isLoading || !this.tabable) {
			return
		}

		if (this.selectedTab == tabKey) {
			return
		}

		for (let tab of this.tabs) {
			tab.editable = false
		}

		this.selectedTab = tabKey

		let selected = this.tabs.find((v: any) => v.key == tabKey)

		this.tabStore.selectTab(tabKey)
		this.clickTab.emit(selected.params)
	}

	toggle(tabKey: string, visible: boolean) {
		if (visible) {
			this.hoveredTab = tabKey
		} else {
			this.hoveredTab = null
		}
	}

	getTabWidth(tabKey: string) {
		return this.hoveredTab === tabKey || this.selectedTab === tabKey ? 'auto' : '100px'
	}

	getAnimationState(tabKey: string) {
		return this.hoveredTab === tabKey || this.selectedTab === tabKey ? 'in' : 'out'
	}

	edit = (event: Event, index: number) => {
		event.stopPropagation()
		this.tabs[index].editable = true
		setTimeout(() => {
			this.editInput.nativeElement.focus()
		})
		event.preventDefault()
	}

	editClickOutside(tabKey: string) {
		if (this.isLoading) {
			return
		}
		for (let tab of this.tabs) {
			tab.editable = false
		}

		let index = this.tabs.findIndex((tab: any) => tab.key === tabKey)

		if (isEmpty(this.tabs[index].name)) {
			this.tabs[index].name = 'Result'
		}

		if (this.tabs[index].isSaved) {
			this.tabStore.saveTab(tabKey, true)
		}
	}

	star = (event: Event, tabKey: string, isSaved: boolean) => {
		if (this.isLoading) {
			return
		}
		let index = this.tabs.findIndex((tab: any) => tab.key === tabKey)
		event.stopPropagation()
		this.tabs[index].isSaved = isSaved
		this.tabStore.saveTab(tabKey, isSaved)
		event.preventDefault()
	}

	close = (event: Event, tabKey: string) => {
		event.stopPropagation()
		if (this.isLoading) {
			return
		}

		if (this.tabs.length == 1) {
			return
		}
		let index = this.tabs.findIndex((tab: any) => tab.key === tabKey)

		this.hoveredTab = null
		this.tabStore.removeTab(tabKey)

		if (this.selectedTab == tabKey) {
			this.adjustTab()
		}

		event.preventDefault()
	}

	adjustTab = () => {
		if (this.tabs.length == 1) {
			this.selectTab('all')
			return
		}

		if (
			this.tabs.some((v: any) => {
				return v.key != 'all'
			})
		) {
			let last = this.tabs.length - 1
			if (last > -1) {
				this.selectTab(this.tabs[last].key)
			}
		}
	}
}
