import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { AuthService } from '@services/auth.service'
import { TaskService } from '@services/task.service'
@Component({
	selector: 'app-task-index',
	templateUrl: './index.component.html',
})
export class TaskIndexComponent implements OnInit, AfterViewInit {
	constructor(
		private cdr: ChangeDetectorRef,
		private taskService: TaskService,
		public authService: AuthService
	) {}
	@ViewChild('taskButton') taskButton: ElementRef
	isOpenTaskList: boolean = false
	selectedTask: any = {}
	@Input() data: any = {}

	ngOnInit(): void {
		// this.taskService.syncData(this.data)
	}

	ngAfterViewInit(): void {}

	openTask(taskId: any) {
		this.taskService.close()
		this.taskService.open(taskId, this.data)
	}

	openTaskList = () => {
		this.isOpenTaskList = true
		this.cdr.detectChanges()
	}

	closeModal = (e: any) => {
		if (e?.task) {
			this.data.task = e.task
			this.taskButton.nativeElement.click()
		} else {
			this.data.task = {}
		}

		this.isOpenTaskList = false
		this.cdr.detectChanges()
	}
}
