<div ngbDropdown class="d-inline-block" #myDrop="ngbDropdown">
	<button
		type="button"
		(focus)="myDrop.open()"
		class="btn btn-flex btn-color-gray-700 btn-active-color-primary rotate fs-base align-items-center px-0"
		data-kt-menu-trigger="click"
		data-kt-menu-placement="bottom-start"
		data-kt-menu-offset="0px, 0px">
		<img data-kt-element="current-lang-flag" class="w-20px h-20px rounded me-3" [src]="country.src" alt="" />

		<span class="me-1 text-grey-800">{{ country.text }}</span>

		<i class="bi bi-chevron-down ms-2 fs-8"></i>

		<div ngbDropdownMenu aria-labelledby="dropdown1">
			<div class="menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7">
				@for (country of countries; track $index) {
					<div class="menu-item px-3" (click)="select(country.value)">
						<a class="menu-link d-flex px-5" [ngClass]="{ disabled: country.disabled }" data-kt-lang="English">
							<span class="symbol symbol-20px me-4">
								<img data-kt-element="lang-flag" class="rounded-1" [src]="country.src" alt="" />
							</span>
							<span class="text-gray-800">{{ country.text }}</span>
						</a>
					</div>
				}
			</div>
		</div>
	</button>
</div>
