<div class="d-flex align-items-center gap-4">
	<!-- <button type="button" class="btn btn-primary btn-sm d-flex align-items-center gap-1" (click)="openTaskList()">
		<i class="bi bi-list-task"></i>
		<span>My Task</span>
	</button> -->

	<button
		(click)="openTask(null)"
		(closeModal)="closeModal($event)"
		type="button"
		class="btn btn-primary btn-sm d-flex align-items-center gap-1">
		<i class="bi bi-list-task"></i>
		<span>Assign Task</span>
	</button>
</div>
