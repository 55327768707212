import Swal from 'sweetalert2'

let lastScript

const scriptReg = /\<script.*src=["'](?<src>[^"']+)/gm
const duration = 30000 // 30 seconds

async function extractNewScripts() {
	const html = await fetch('/?_timestamp=' + Date.now()).then((res) => {
		return res.text()
	})

	scriptReg.lastIndex = 0

	let result = []

	let match

	while ((match = scriptReg.exec(html))) {
		result.push(match.groups.src)
	}

	return result
}

async function needUpdate() {
	const newScript = await extractNewScripts()

	if (!lastScript) {
		lastScript = newScript
		return false
	}

	// console.log(lastScript)
	// console.log(newScript)

	let result = false

	if (lastScript.length !== newScript.length) {
		return true
	}

	for (let i = 0; i < lastScript.length; i++) {
		if (lastScript[i] !== newScript[i]) {
			// console.log('old -> ' + lastScript[i])
			// console.log('new -> ' + newScript[i])
			result = true
			return true
		}
	}

	return false
}

function autoRefresh() {
	let timeout = setTimeout(async () => {
		const willUpdate = await needUpdate()

		if (willUpdate) {
			Swal.fire({
				title: 'System Updated',
				text: 'System is updated, please reload the page',
				icon: 'info',
				showCancelButton: false,
				allowOutsideClick: false,
				confirmButtonText: 'Reload',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then((res) => {
				if (res.isConfirmed) {
					location.reload()
				}
			})

			clearTimeout(timeout)
			return
		}
		autoRefresh()
	}, duration)
}

autoRefresh()
