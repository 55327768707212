import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { ToastService } from '@shared/toast/toast.service'

@Component({
	selector: 'app-clipboard',
	templateUrl: './clipboard.component.html',
	styleUrls: ['./clipboard.component.scss'],
})
export class ClipboardComponent implements OnInit {
	constructor(public toastService: ToastService, private cdr: ChangeDetectorRef) {}

	@Input() value: any
	ngOnInit(): void {}
	iconClass: string = 'bi bi-clipboard text-gray-800 fs-6'

	doCopy(e: Event) {
		e.stopPropagation()

		this.toastService.show('Text Copied!', {
			delay: 2000,
			autohide: true,
			headertext: 'System Reminder',
			icon: 'bi bi-clipboard',
		})

		this.iconClass = 'bi bi-clipboard-check-fill'
		this.cdr.detectChanges()

		setTimeout(() => {
			this.iconClass = 'bi bi-clipboard'
			this.cdr.detectChanges()
		}, 1000)

		navigator.clipboard.writeText(this.value).catch(() => {
			console.error('Unable to copy text')
		})

		e.preventDefault()
	}
}
