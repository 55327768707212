<ng-template #content>
	@if (isShowLoading$ | async) {
		<div
			[ngClass]="{
				'overlay overlay-block min-h-600px': isShowLoading$ | async
			}">
			<div class="overlay-layer card-rounded d-flex flex-column" *ngIf="isShowLoading$ | async">
				<div class="spinner-border text-black" role="status"></div>
			</div>
		</div>
	} @else {
		<div class="modal-header">
			<h2>
				Task
				@if (selectedTask.related_title) {
					- Related with <b>{{ selectedTask.related_title }}</b>
				}
			</h2>
			<div class="btn btn-sm btn-icon btn-active-color-primary" (click)="close()">
				<span class="bi bi-x-lg"></span>
			</div>
		</div>

		<app-custom-form-group #formGroup [formGroup]="form" (ngSubmit)="submit()">
			<div class="modal-body">
				@if (selectedTask?.uuid) {
					<div class="mb-8 d-flex flex-stack">
						<div>
							<label class="text-gray-800 fs-3 fw-semibold mb-3"> Creator </label>

							<div>
								<span class="fs-4 text-gray-800">
									{{ selectedTask?.admin?.name }} - {{ selectedTask?.admin?.email }}
								</span>
							</div>
						</div>
						<div>
							{{ selectedTask.created_at | customDate }}
						</div>
					</div>
				}
				<div class="mb-8">
					<label class="text-gray-800 fs-4 fw-semibold mb-3"> Involve Members </label>

					<div *ngIf="selectedMembers.length">
						<div class="symbol-group symbol-hover">
							<div class="symbol symbol-35px symbol-circle" *ngFor="let member of selectedMembers; let i = index">
								<ng-template #tipContent>
									<div class="min-w-125px">
										<b>{{ member.name }}</b>
									</div>
								</ng-template>

								<span class="symbol-label 40px" [ngClass]="avatarClass(i)" [ngbTooltip]="tipContent">{{
									member.name.charAt(0)
								}}</span>
							</div>
						</div>
					</div>
					<div *ngIf="!selectedMembers.length">
						<span> <i> No any members involved yet </i> </span>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-9">
						<div class="mb-6">
							<label class="text-gray-800 fs-4 fw-semibold mb-3"> Title </label>
							<app-custom-form-control [fc]="form.get('title')" field="title">
								<input type="text" class="form-control" name="title" formControlName="title" />
							</app-custom-form-control>
						</div>

						<div class="mb-6">
							<label class="text-gray-800 fs-4 fw-semibold mb-3"> Description </label>
							<app-custom-form-control [fc]="form.get('description')" field="description">
								<textarea class="form-control" name="description" formControlName="description" rows="6"></textarea>
							</app-custom-form-control>
						</div>

						<div class="mb-6" [hidden]="selectedTask.uuid">
							<div class="d-flex flex-stack align-items-center">
								<label class="text-gray-800 fs-4 fw-semibold mb-3"> Url </label>
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										id="urlCheck"
										[value]="true"
										formControlName="isCheckUrl" />
									<label class="form-check-label" for="urlCheck"> Current page url </label>
								</div>
							</div>
							<app-custom-form-control [fc]="form.get('url')" field="url">
								<input type="text" class="form-control" name="url" formControlName="url" />
							</app-custom-form-control>
						</div>

						<!-- <div class="mb-6">
							<div class="d-flex flex-stack align-items-center mb-3">
								<label class="text-gray-800 fs-4 fw-semibold"> Checklist </label>

								<button type="button" class="btn btn-secondary btn-sm" (click)="addItem()">Add</button>
							</div>
							<div formArrayName="items">
								<div class="mb-3" *ngFor="let item of items.controls; let i = index" [formGroupName]="i">
									<div class="d-flex align-items-center gap-4 w-100">
										<div class="form-check">
											<input class="form-check-input" type="checkbox" [value]="true" formControlName="isChecked" />
										</div>
										<div class="d-flex align-items-center gap-4 w-100">
											<span>{{ i + 1 }})</span>

											<app-custom-form-control [fc]="item.get('itemName')" field="item">
												<input type="text" class="form-control" formControlName="itemName" />
											</app-custom-form-control>
										</div>

										<i class="bi bi-x-circle fs-3 text-danger cursor-pointer" (click)="removeItem(i)"></i>
									</div>
								</div>
							</div>
						</div> -->

						@if (selectedTask?.uuid) {
							<app-task-comment [taskId]="selectedTask.uuid"></app-task-comment>
						}

						<app-task-history
							[histories]="selectedTask?.histories"
							*ngIf="selectedTask?.histories?.length"></app-task-history>
					</div>

					<div class="col-12 col-md-3">
						<label class="text-gray-800 fs-4 fw-semibold mb-3">Action</label>
						<div class="d-flex flex-column gap-3">
							<button
								*ngIf="!isJoined"
								type="button"
								class="btn btn-sm btn-secondary w-100 text-start d-flex align-items-center"
								(click)="join()">
								<span class="bi bi-person me-3 fs-3"></span>
								<span>Join</span>
							</button>
							<button
								*ngIf="isJoined"
								type="button"
								class="btn btn-sm btn-danger w-100 text-start d-flex align-items-center"
								(click)="leave(me)">
								<span class="bi bi-door-open me-3 fs-3"></span>
								<span>Leave</span>
							</button>

							<app-task-member
								[selectedMembers]="selectedMembers"
								(selectMember)="selectMember($event)"
								(unSelectMember)="unSelectMember($event)"></app-task-member>

							<a
								*ngIf="selectedTask.url"
								[href]="selectedTask.url"
								type="button"
								target="_blank"
								class="btn btn-sm btn-secondary w-100 text-start d-flex align-items-center">
								<span class="bi bi-reply me-3 fs-3"></span>
								<span>Redirect</span>
							</a>

							@if (selectedTask?.uuid && selectedTask?.itemable_type == 'App\\Models\\Vehicle\\Vehicle') {
								<button
									type="button"
									class="btn btn-sm btn-info w-100 text-start d-flex align-items-center"
									(click)="goToCarpark()">
									<span class="bi bi-reply me-3 fs-3"></span>
									<span>Go to Carpark</span>
								</button>

								@if (selectedTask.status !== 2) {
									<button
										type="button"
										(click)="markAsComplete()"
										class="btn btn-primary btn-sm w-100 text-start d-flex align-items-center"
										[disabled]="isLoading$ | async">
										<ng-container *ngIf="isLoading$ | async">
											<span class="indicator-progress" [style.display]="'block'">
												Please wait...
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										</ng-container>
										<ng-container *ngIf="!(isLoading$ | async)"> Enable vehicle </ng-container>
									</button>
								}
							}

							<!-- <app-custom-form-control class="flex-2" [fc]="form.get('status')" field="status">
								<ng-select
									formControlName="status"
									class="custom w-100 text-start d-flex align-items-center"
									[clearable]="false"
									[clearOnBackspace]="false">
									@for (status of status; track $index) {
										<ng-option class="form-control" [value]="status.value"> {{ status.name }} </ng-option>
									}
								</ng-select>
							</app-custom-form-control> -->

							<!-- @if (selectedTask?.admin?.uuid == me.uuid || !selectedTask?.uuid) {
							<div class="d-flex flex-stack align-items-center mt-3">
								<label class="form-check-label flex-3" for="taskEnabled">
									<span *ngIf="form.get('is_able_edit').value">Allow assignee edit</span>
									<span *ngIf="!form.get('is_able_edit').value">Disallow assignee edit</span>
								</label>
								<app-custom-form-control class="flex-2" [fc]="form.get('is_able_edit')" field="able edit">
									<div class="form-check form-switch form-check-custom form-check-solid justify-content-end">
										<input
											name="is_able_edit"
											formControlName="is_able_edit"
											class="form-check-input ms-4"
											type="checkbox"
											id="taskEnabled" />
									</div>
								</app-custom-form-control>
							</div>
						} -->
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer d-flex flex-stack">
				<div>
					<app-custom-form-control [fc]="form.get('status')" field="title">
						<select formControlName="status" class="form-select form-select-sm">
							<option [value]="0">Pending</option>
							<option [value]="1">In Progress</option>
							<option [value]="2">Completed</option>
						</select>
					</app-custom-form-control>
				</div>
				<div class="d-flex gap-4">
					<button type="button" class="btn btn-secondary btn-sm" (click)="close()">
						<span> Close </span>
					</button>

					<button
						*ngIf="selectedTask?.admin?.uuid == me.uuid && selectedTask.oriStatus != 2"
						(click)="warning()"
						type="button"
						class="btn btn-danger btn-sm"
						[disabled]="form.invalid || (isLoading$ | async)">
						<ng-container *ngIf="isLoading$ | async">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
						<ng-container *ngIf="!(isLoading$ | async)"> Delete </ng-container>
					</button>
					<button type="submit" class="btn btn-primary btn-sm" [disabled]="form.invalid || (isLoading$ | async)">
						<ng-container *ngIf="isLoading$ | async">
							<span class="indicator-progress" [style.display]="'block'">
								Please wait...
								<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</ng-container>
						<ng-container *ngIf="!(isLoading$ | async)"> Submit </ng-container>
					</button>
				</div>
			</div>
		</app-custom-form-group>
	}
</ng-template>
