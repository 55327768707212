import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-error-403',
	templateUrl: './error403.component.html',
})
export class CustomError403 implements OnInit {
	@Input() histories: any
	@Input() showHeader: boolean = true

	constructor() {}

	ngOnInit() {
		// this.histories.sort((a: any, b: any) => +new Date(b.created_at) - +new Date(a.created_at))
	}
}
