import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modalState = new Subject<any>()

	modalState$ = this.modalState.asObservable()

	open(data: any = {}) {
		this.modalState.next({ open: true, data: data })
	}

	close() {
		this.modalState.next({ open: false, data: {} })
	}
}
