import { Pipe, PipeTransform } from '@angular/core'
import { convertPricing } from '@helper/helper'

@Pipe({
	name: 'convertPricing',
})
export class ConvertPricingPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (args.length > 0 && typeof args[0] === 'object') {
			return convertPricing(value, args[0])
		} else {
			return convertPricing(value)
		}
	}
}
