import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class TaskService {
	constructor(private http: HttpClient) {}
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	private isShowLoadingSubject = new BehaviorSubject<boolean>(false)
	public isShowLoading$ = this.isShowLoadingSubject.asObservable()

	private isCommentLoadingSubject = new BehaviorSubject<boolean>(false)
	public isCommentLoading$ = this.isCommentLoadingSubject.asObservable()

	private isListCommentLoadingSubject = new BehaviorSubject<boolean>(false)
	public isListCommentLoading$ = this.isListCommentLoadingSubject.asObservable()

	private taskIdSubject = new BehaviorSubject<null | string>(null)
	public taskId$ = this.taskIdSubject.asObservable()

	private needRefreshSubject = new BehaviorSubject<boolean>(false)
	public needRefresh$ = this.needRefreshSubject.asObservable()

	private isOpenModalSubject = new BehaviorSubject<boolean>(false)
	public isOpenModal$ = this.isOpenModalSubject.asObservable()

	private isSubmitSubject = new BehaviorSubject<boolean>(false)
	public isSubmit$ = this.isSubmitSubject.asObservable()

	submit() {
		this.isSubmitSubject.next(true)
		this.needRefreshSubject.next(true)
	}

	open(taskId: any = null, data: any = {}) {
		this.isSubmitSubject.next(false)
		this.isOpenModalSubject.next(true)
		this.taskIdSubject.next({ taskId: taskId, ...data })
	}

	close() {
		this.isOpenModalSubject.next(false)
	}

	// syncData = (data: any) => {
	// 	this.dataSubject.next(taskId)
	// }

	myTask = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `my-tasks`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	getTaskList = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `tasks`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	create = (params: any = {}): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `tasks`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	show = (uuid: string, params = {}) => {
		this.isShowLoadingSubject.next(true)
		const url = `tasks/${uuid}/`
		return this.http.get(url, params).pipe(finalize(() => this.isShowLoadingSubject.next(false)))
	}

	listComment = (uuid: string, params = {}) => {
		this.isListCommentLoadingSubject.next(true)
		const url = `tasks/${uuid}/comments`
		return this.http.get(url, params).pipe(finalize(() => this.isListCommentLoadingSubject.next(false)))
	}

	comment = (uuid: string, params = {}) => {
		this.isCommentLoadingSubject.next(true)
		const url = `tasks/${uuid}/comments`
		return this.http.post(url, params).pipe(finalize(() => this.isCommentLoadingSubject.next(false)))
	}

	update = (uuid: string, params = {}) => {
		this.isLoadingSubject.next(true)
		const url = `tasks/${uuid}/`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updateStatus = (uuid: string, params = {}) => {
		this.isLoadingSubject.next(true)
		const url = `tasks/${uuid}/status`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	delete = (uuid: string) => {
		this.isLoadingSubject.next(true)
		const url = `tasks/${uuid}/`
		return this.http.delete(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	markAsComplete = (uuid: string) => {
		this.isLoadingSubject.next(true)
		const url = `tasks/${uuid}/mark-as-complete`
		return this.http.post(url, {}).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
