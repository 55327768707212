import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class AdminService {
	constructor(private http: HttpClient) {}
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	getAdminListData = (params: any = {}): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	getAdminDetail = (uuid: string): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins/${uuid}`
		return this.http.get(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	createAdmin = (params: any): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins/`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updateAdmin = (uuid: string, params: any): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins/${uuid}`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	deleteAdmin = (uuid: string): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins/${uuid}`
		return this.http.delete(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	unbind = (uuid: string): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/admins/${uuid}/unbind`
		return this.http.post(url, {}).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
