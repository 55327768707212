import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http'
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core'
import { resizeImage } from '@helper/helper'
import { AuthService } from '@services/auth.service'
import { Subscription } from 'rxjs'
import Swal from 'sweetalert2'
import Viewer from 'viewerjs'

@Component({
	selector: 'app-image-input',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageInputComponent implements OnInit, AfterViewInit, OnChanges {
	private uploadSubscription: Subscription
	constructor(private cdr: ChangeDetectorRef, private http: HttpClient, private authService: AuthService) {}

	isImageRendering: boolean | undefined = undefined
	isImageUploading: boolean = false
	currentLoaded: number = 0
	totalLoaded: number

	num = 3

	get times() {
		return new Array(this.num)
	}
	@Input() height: any = 'h-100'
	@Input() width: any = 'w-100'
	@Input() url: any = null
	@Input() ableEdit: boolean = true
	@Input() ableDelete: boolean = true
	@Input() ableView: boolean = true
	@Input() src: string | undefined | null = null
	@Input() arraySrc: string[] = []

	defaultImage: any = '/assets/media/default.jpg'
	imageSrc: any = this.defaultImage

	ngOnInit() {
		if (this.src && this.src != this.defaultImage) {
			this.isImageRendering = true
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.src) {
			const newSrc = changes.src.currentValue

			if (newSrc) {
				this.imageSrc = newSrc
			} else {
				this.imageSrc = this.defaultImage
			}

			this.cdr.detectChanges()
		}
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.src) {
				this.imageSrc = this.src
				this.cdr.detectChanges()
			}
		}, 1000)
		const viewer = new Viewer(this.imageContainer.nativeElement, {})
		this.cdr.detectChanges()
	}

	@ViewChild('imageContainer') imageContainer: ElementRef
	@ViewChild('fileInput', { static: false }) fileInput: ElementRef
	@ViewChild('gallery', { static: false }) gallery: ElementRef

	@Output() selectImage: EventEmitter<any> = new EventEmitter<any>()
	@Output() deleteImage: EventEmitter<any> = new EventEmitter<any>()

	get checkImageSrcIsArray() {
		return Array.isArray(this.imageSrc)
	}

	triggerImageChange(event: Event) {
		event.stopPropagation()
		if (!this.ableEdit) {
			return
		}
		this.fileInput.nativeElement.click()
	}

	async changeImage(event: any) {
		event.stopPropagation()

		if (!this.ableEdit) {
			return
		}

		let file = event.target.files[0]
		let result = await resizeImage(file)

		if (result.status !== 'success') {
			return
		}

		if (this.url && file) {
			this.uploadFile(file, result.base64)
		} else {
			this.imageSrc = result.base64
		}

		let data: any = {
			file: file,
			resizedBase64: this.imageSrc,
		}

		this.selectImage.emit(data)
		this.cdr.detectChanges()
	}

	uploadFile(file: File, base64: any) {
		this.isImageUploading = true

		let user = this.authService.getAuthFromLocalStorage()
		let bearerToken = `Bearer ${user?.bearerToken}`

		if (this.url) {
			const req = new HttpRequest(
				'POST',
				this.url,
				{
					base64: base64,
				},
				{
					reportProgress: true,
					headers: new HttpHeaders({
						Authorization: bearerToken,
						Accept: `application/json`,
						'Content-Type': `application/json`,
					}),
				}
			)

			this.uploadSubscription = this.http.request(req).subscribe(
				(event: any) => {
					if (event?.ok) {
						this.imageSrc = base64
						this.currentLoaded = 0
						this.isImageUploading = false
						this.cdr.detectChanges()
					}

					if (event.loaded && event.total) {
						this.drawProgressBar(event.loaded, event.total)
					}

					if (event instanceof HttpResponse) {
						let { status } = event.body
						if (status) {
							this.isImageUploading = false
							Swal.fire({
								title: 'Upload image successfully',
								icon: 'success',
								showCancelButton: false,
							})
						}
					}
				},
				(error: any) => {
					console.log(error)
					Swal.fire({
						title: 'Upload image failed',
						icon: 'error',
						showCancelButton: false,
					})
				}
			)
		}
	}

	cancelUpload() {
		this.currentLoaded = 0
		this.isImageUploading = false
		if (this.uploadSubscription) {
			this.uploadSubscription.unsubscribe()
		}
	}

	removeImage(event: Event) {
		event.stopPropagation()
		this.imageSrc = this.defaultImage
		this.cancelUpload()
		this.fileInput.nativeElement.value = null
		this.cdr.detectChanges()
		this.deleteImage.emit(true)
	}

	viewImage(event: Event) {
		event.stopPropagation()
		this.gallery.nativeElement.click()
	}

	clickImageContainer(event: Event) {
		if (!this.canRemoveImage && !this.canViewImage) {
			if (!this.ableEdit) {
				return
			}
			event.stopPropagation()
			this.fileInput.nativeElement.click()
		} else if (this.canViewImage) {
			// event.stopPropagation()
			// this.viewImage(event)
		}
	}

	drawProgressBar(currentSize: number, totalSize: number) {
		this.currentLoaded = Number(((currentSize / totalSize) * 100).toFixed(2))

		if (this.currentLoaded >= 99) {
			this.currentLoaded = 99
		}

		this.cdr.detectChanges()
		// this.totalLoaded = totalSize
	}

	loadingImage() {
		setTimeout(() => {
			this.isImageRendering = false
			this.cdr.detectChanges()
		}, 1500)
	}

	get canRemoveImage() {
		if (this.arraySrc.length) {
			return false
		}
		return this.imageSrc != this.defaultImage
	}

	get canViewImage() {
		if (this.arraySrc.length) {
			return true
		}
		return this.imageSrc != this.defaultImage && this.ableView
	}

	get blurAbleClass() {
		return !this.ableView ? 'no-blur' : ''
	}
}
