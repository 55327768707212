import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class FormService {
	private bridge = new BehaviorSubject<any>({})
	bridge$ = this.bridge.asObservable()
	form: any | null = null
	forms: any = []

	initialize(form: any) {
		this.form = form
		this.forms.push(form)
		this.bridge.next(form)
	}

	setErrors(error: any) {
		this.forms.forEach((form: any) => {
			form.setErrors(error)
		})
	}

	unsubscribe(uuid: string) {
		let index = this.forms.findIndex((f: any) => f.uuid == uuid)
		this.forms.splice(index, 1)
		this.form = null
		this.bridge.next(null)
	}

	validate() {
		this.forms.forEach((form: any) => {
			form.validate()
		})
	}
}
