import { Component, Input, OnInit } from '@angular/core'
import { getStatusBadge, isEmpty } from '@helper/helper'
import { AuthService } from '@services/auth.service'
import moment from 'moment'

@Component({
	selector: 'app-task-history',
	templateUrl: './history.component.html',
})
export class TaskHistoryComponent implements OnInit {
	constructor(private authService: AuthService) {}

	isEmpty = isEmpty
	me = this.authService.getAuthFromLocalStorage()

	@Input() histories: any = []

	getStatusBadge = getStatusBadge

	ngOnInit(): void {
		this.histories = this.histories.filter((v: any) => v.event == 'UPDATE')
	}

	formatTime(time: string) {
		if (isEmpty(time)) {
			return
		}

		return moment.utc(time).fromNow()
	}

	avatarClass(index: number): string {
		index = index % 5
		let colors: any = {
			0: 'bg-light-primary text-primary',
			1: 'bg-light-warning text-warning',
			2: 'bg-light-danger text-danger',
			3: 'bg-light-info text-info',
			4: 'bg-light-success text-success',
		}

		return colors[index]
	}

	getKeys(json: any) {
		return Object.keys(json).join(',')
	}
}
