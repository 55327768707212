import { Injectable } from '@angular/core'
import { BridgeModel } from '@models/bridge.model'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class BridgeService {
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()
	private bridge = new BehaviorSubject<BridgeModel>({})
	bridge$ = this.bridge.asObservable()
	data: {}

	initialize(pageObject: BridgeModel) {
		this.data = pageObject
		this.bridge.next(pageObject)
	}

	recursiveMerge(target: any, source: any): any {
		if (typeof target !== 'object' || typeof source !== 'object') {
			return source
		}

		for (const key in source) {
			if (source[key] && typeof source[key] === 'object') {
				if (!target[key]) {
					target[key] = {}
				}
				this.recursiveMerge(target[key], source[key])
			} else {
				target[key] = source[key]
			}
		}
		return target
	}

	update(pageObject: BridgeModel) {
		this.data = this.recursiveMerge(this.data, pageObject)
		this.bridge.next(this.data)
	}

	unsubscribe() {
		this.data = {}
		this.bridge.next(this.data)
	}
}
