import {
	HttpEvent,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpInterceptor as originHttpInterceptor,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslationService } from '@assets/i18n'
import { environment } from '@env/environment'
import { formatPayload, isEmpty } from '@helper/helper'
import { FormService } from '@services/form.service'
import { ToastService } from '@shared/toast/toast.service'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { AuthService } from './auth.service'
import { BridgeService } from './bridge.service'

@Injectable({
	providedIn: 'root',
})
export class HttpInterceptor implements originHttpInterceptor {
	constructor(
		private authService: AuthService,
		private router: Router,
		private toastService: ToastService,
		private bridgeService: BridgeService,
		private translationService: TranslationService,
		private formService: FormService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		//get request headers

		let bearerToken: string | undefined = request.headers.get('Authorization') || ''

		let country: string = request.headers.get('Country') || ''
		let requestHasCountryHeader: boolean = false

		if (!isEmpty(country)) {
			requestHasCountryHeader = true
		}

		if (!bearerToken) {
			let user = this.authService.getAuthFromLocalStorage()
			bearerToken = `Bearer ${user?.bearerToken}`

			if (!requestHasCountryHeader) {
				country = user?.country || 'sg'
			}
		}

		let domain: any = environment.apiUrl

		let url = request.url.replace(/^\//, '')

		if (url.includes('.svg')) domain = ''

		let formatedParams = request.params
		let formatedBody = request.body
		// let formatedParams = this.formatParams(request)
		// let formatedBody = this.formatBody(request)

		let ip = localStorage.getItem('ipAddress')

		request = request.clone({
			url: `${domain}${url}`,
			params: formatedParams,
			body: formatedBody,
			reportProgress: true,
			setHeaders: {
				Authorization: bearerToken,
				Accept: `application/json`,
				Country: country,
				IP: ip || '',
				'Content-Type': `application/json`,
				'Accept-Language': this.translationService?.getSelectedLanguage() || 'en',
			},
		})
		return next.handle(request).pipe(
			tap(
				(event) => {
					if (event instanceof HttpResponse) {
						if (event.body?.data) {
							formatPayload(event.body.data, 'add')
						}
					}
				},
				(error) => {
					if (error?.status) {
						switch (error.status) {
							case 400:
								Swal.fire({
									title: 'System Reminder',
									text: error.error.message,
									icon: 'error',
								})
								break
							case 401:
								if (error?.error?.mfa == 'disabled') {
									if (error?.error?.user) {
										this.authService.setAuthFromLocalStorage(error.error.user)
										return this.router.navigate(['/auth/google2fa'])
									}
								}
								this.authService.logout({ silent: true })
								break
							case 403:
								this.bridgeService.update({
									pageLoading: false,
									pageError: 403,
								})
								break
							case 404:
								this.router.navigate(['**'])
								break
							case 422:
								if (this.formService.forms.length) {
									this.formService.setErrors(error)
								}

								break
							case 500:
								this.toastService.show('Please try again later', {
									delay: 3000,
									icon: `bi bi-exclamation-triangle`,
									autohide: true,
									classname: 'bg-danger text-white',
									headertext: 'Oops...Something Wrong',
								})
								break
						}
					}
				}
			)
		)
	}

	// formatBody(request: any) {
	// 	return request.body
	// 	// return formatPayload(request.body, 'subtract')
	// }

	// formatParams(request: any) {
	// 	let paramsObject: { [key: string]: string | null } = {}
	// 	let modifiedParams = new HttpParams()

	// 	request.params.keys().forEach((key: any) => {
	// 		const value = request.params.get(key)
	// 		if (value !== null) {
	// 			paramsObject[key] = value
	// 		}
	// 	})

	// 	let formatedParams = formatPayload(paramsObject, 'subtract')

	// 	for (let key in formatedParams) {
	// 		let value: any = formatedParams[key]
	// 		modifiedParams = modifiedParams.set(key, value)
	// 	}

	// 	return modifiedParams
	// }
}
