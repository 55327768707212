let adminDomain = 'admin.shariot.com'
let dealerDomain = 'dealer.shariot.com'
let apiDomain = 'https://admin-middleware.shariot.com'
let apiUrl

if (location.hostname == adminDomain) {
	apiUrl = `${apiDomain}/api/`
} else if (location.hostname == dealerDomain) {
	apiUrl = `${apiDomain}/dealer/api/`
}

export const environment = {
	env: 'prod',
	appName: 'Shariot',
	production: true,
	apiDomain: apiDomain,
	adminDomain: adminDomain,
	dealerDomain: dealerDomain,
	appVersion: 'v2.0.22',
	USERDATA_KEY: 'authf649fc9a5f55',
	apiUrl: apiUrl,
	websocket: 'wss://tracker-ws.shariot.com:22703',
	ws_token: 'xcdsdyGCyV875vvCD2xK0nPGe1zzkintSGS2SCQkO59uPScDvM2IUS7WFLSN',
	timezone: 8,
	appDemos: '',
	appThemeName: '',
	appPreviewUrl: '',
	appPurchaseUrl: '',
	appPreviewDocsUrl: '',
	appPreviewChangelogUrl: '',
}
