import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '../services/auth.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.authService.getAuthFromLocalStorage()

		if (currentUser) {
			if (!currentUser.is_enable_mfa) {
				this.router.navigate(['/auth/google2fa'])
				return false
			}
			return true
		}

		this.authService.logout({ silent: true })
		return false
	}
}
