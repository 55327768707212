<router-outlet></router-outlet>
<app-toast></app-toast>
<app-task-modal *ngIf="showTaskModal"></app-task-modal>

@if (!isEmpty(me) && getPlatform() == 'dealer') {
	@if (isEmpty(me?.company) || me?.company?.status == 0 || me?.company?.status == 2) {
		<span class="demo-icon"> DEMO </span>
	} @else if (me?.is_force_login == true) {
		<span class="demo-icon"> 强制登入 </span>
	}
}
