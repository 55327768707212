<div class="card shadow-sm">
	<div class="card-header" *ngIf="showHeader">
		<div class="card-title">
			<h2 class="fw-bold">History</h2>
		</div>
	</div>

	<ng-container *ngIf="histories.length; else noData">
		<div class="p-6 overflow-scroll max-h-300px history">
			<div class="timeline-label">
				<div class="timeline-item align-items-center gap-4" *ngFor="let history of histories">
					<div class="timeline-label fw-bolder text-gray-800 fs-6">
						<span>
							{{ history.created_at | date: 'HH:mm' }}
						</span>
					</div>

					<div class="timeline-badge">
						<div [ngSwitch]="history?.event">
							<i *ngSwitchCase="'UPDATE'" class="fa fa-genderless text-warning fs-1"></i>
							<i *ngSwitchCase="'CREATE'" class="fa fa-genderless text-success fs-1"></i>
							<i *ngSwitchCase="'DELETE'" class="fa fa-genderless text-danger fs-1"></i>
							<i *ngSwitchDefault class="fa fa-genderless text-secondary fs-1"></i>
						</div>
					</div>

					<div class="d-flex flex-column">
						<div class="fw-semibold fs-6 text-gray-800 d-flex align-items-center gap-4">
							<span>
								{{ history.created_at | date: 'dd-MM-yyyy' }}
							</span>
							<button
								*ngIf="authService.hasRoles(['superadmin'])"
								class="btn btn-outline btn-xs"
								type="button"
								(click)="openHistoryModal(history)">
								<span class="bi bi-search fs-7"></span>
							</button>
						</div>
						@if (history?.title) {
							<div class="d-flex flex-column my-1 line-height-18">
								<span class="fs-4 text-gray-800 fw-bold">{{ history?.title }}</span>
								<span class="fw-semibold text-grey-600 fs-4" *ngIf="history?.description">
									{{ history?.description }}
								</span>
							</div>
						} @else {
							<div
								class="d-flex flex-column my-1 line-height-18"
								*ngIf="history?.remark?.title || history?.remark?.remark">
								<span class="fs-4 text-gray-800 fw-bold">{{ history?.remark?.title }}</span>
								<span class="fw-semibold text-grey-600 fs-4" *ngIf="history?.remark?.remark">
									{{ history?.remark?.remark }}
								</span>
							</div>
						}
						<div class="fw-mormal timeline-content text-gray-600" *ngIf="history?.event">
							<span
								*ngIf="
									history.guardable_type == 'App\\Models\\Auth\\Admin' && history.guardable_id == 1;
									else notSystem
								">
								{{
									'HISTORY.ACTION.' + history?.event
										| translate
											: {
													guardable: 'HISTORY.GUARDABLE.SYSTEM' | translate
											  }
								}}
							</span>
							<ng-template #notSystem>
								{{
									'HISTORY.ACTION.' + history?.event
										| translate
											: {
													guardable: ('HISTORY.GUARDABLE.' + history.guardable_type | translate)
											  }
								}}

								{{ history?.guardable?.name || '-' }}
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #noData>
		<div class="card-body min-h-150px d-flex align-items-center justify-content-center">
			<i>-- {{ 'DATANOTFOUND' | translate }} --</i>
		</div>
	</ng-template>
	<app-history-detail-modal
		*ngIf="isOpenHistory"
		[history]="selectedHistory"
		(closeModal)="closeModal($event)"></app-history-detail-modal>
</div>
