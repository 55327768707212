// prettier-ignore
export default {
  TRANSLATOR: {
    SELECT: 'Select your language',
  },
  DATANOTFOUND: 'Data not found',
  HISTORY: {
    GUARDABLE: {
      'SYSTEM': 'SYSTEM',
      'App\\Models\\User\\User': 'user',
      'App\\Models\\Auth\\Admin': 'admin',
      'App\\Models\\Dealer\\Dealer': 'dealer',
    },
    ACTION: {
      CREATE: 'Created by {{guardable}}',
      UPDATE: 'Updated by {{guardable}}',
      DELETE: 'Deleted by {{guardable}}',
      ACTIVATED: 'Activated by {{guardable}}',
      LOGIN: 'Login by {{guardable}}',
      LOGOUT: 'Logout by {{guardable}}',
      INACTIVE: 'Inactive by {{guardable}}',
      VIEWED: 'Viewed by {{guardable}}'
    },
  },
  MONTH: {
    JAN: 'January',
    FEB: 'February',
    MAR: 'March',
    APR: 'April',
    MAY: 'May',
    JUN: 'June',
    JUL: 'July',
    AUG: 'August',
    SEP: 'September',
    OCT: 'October',
    NOV: 'November',
    DEC: 'December',
  },
  VALIDATION: {
    REQUIRED: 'The {{field}} field is required.',
    EMAIL: 'The {{field}} field is an invalid email format.',
    NUMBER: 'The {{field}} field is not a valid number.',
    MINCHECK: 'The {{field}} is required at least {{value}}.',
    MIN: 'The {{field}} should be at least {{value}}.',
    MAX: 'The {{field}} should not exceed {{value}}.',
    MINLENGTH: '{{field}} should have at least {{value}} symbols.',
    MAXLENGTH: '{{field}} should have maximum {{value}} symbols.',
    URL: 'The {{field}} is an invalid url',
    ALPHANUMERIC: 'Thie {{field}} is only accept alphanumeric.',
    MISMATCH: 'The {{field}} does no match with {{target}}.',
    NOSCRIPTTAG: 'The {{field}} may not contain HTML tags.',
    ATLEAST: 'The {{field}} is at least select {{value}}'
  },
  STATUS: {
    REJECT: 'Reject',
    APPROVE:'Approve',
    DELETED:'Deleted',
    ACCOUNT_CLOSED: 'Account Closed',
    PENDING_CLOSE_ACCOUNT: 'Pending Close Account',
    BLOCKED: 'Blocked',
    REJECTED: 'Rejected',
    DEPOSITED: 'Deposited',
    PENDING: 'Pending',
    APPROVED: 'Approved',
    PENDING_DEPOSIT: 'Pending Deposit',
    VERIFIED: 'Verified',
    PENDING_VERIFICATION: 'Pending Verification',
    PENDING_APPROVAL: 'Pending Approval',
    PENDING_REFUND: 'Pending Refund',
    RESERVED: 'Reserved',
    UPCOMING: 'Upcoming',
    ONGOING: 'On-going',
    CANCELLED: 'Cancelled',
    COMPLETED: 'Completed',
    RESERVE_CANCELLED: 'Reserve Cancelled',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
    LATE: 'Late',
    VOIDED: 'Voided',
    PARTIALLY_REFUND: 'Partially Refund',
    REFUNDED: 'Refunded',
    REAUTH_FAIL: 'Reauth Fail',
  },
  REFUND_TYPE: {
    FORCE: 'FORCE END TRIP',
    LESSTANK: 'LESS THAN 1/4 TANK LEVEL',
    CANCEL: 'Cancel',
    COMPLETED: 'REFUND FOR COMPLETED TRIP',
    OTHERS: 'OTHERS',
  },
  ACTION: {
    ACTION: 'Action',
    BLOCK: 'Block',
    UNBLOCK: 'UnBlock',
    UPLOAD: 'Upload',
    RETRY: 'Retry',
    RESET: 'Reset',
    SELECTATYPE: 'Please select a search terms',
    SELECTABRAND: 'Please select a brand',
    ADD: 'Add',
    EXPORT: 'Export',
    SEARCH: 'Search',
    DELETE: 'Delete',
    EDIT: 'Edit',
    SUBMIT: 'Submit',
    REFUNDED: 'Mark as Refunded',
    REJECT: 'Reject',
    APPROVE: 'Approve',
    BACK: 'Back',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    APPLY: 'Apply',
    BIND: 'Bind',
    UNBIND: 'Unbind',
    PROCESS: 'Process',
    TRACKER: 'Show Status Record(s)',
    ROUTE: 'Show Routes',
    COMPLETE: 'Complete',
  },
  TABLE: {
    PAGESIZE: 'Page Size',
    PAGE: 'Page',
  },
  MENU: {
    NEW: 'new',
    ACTIONS: 'Actions',
    CREATE_POST: 'Create New Post',
    PAGES: 'Pages',
    FEATURES: 'Features',
    APPS: 'Apps',
    DASHBOARD: 'Dashboard',
    VINCENT_DASHBOARD: 'Vincent Dashboard',
    TASK: 'Task List',
    REGISTER: {
      GENERAL: {
        TYPE: 'Type',
        REASON: 'Reason',
        TRANSACTIONID: 'Transaction ID',
        MPGSORDERID: 'Mpgs Order ID',
        SELFTRANSACTIONID: 'Self Transaction ID',
        SELECTATYPE: 'Select a search terms',
        NAME: 'Name',
        NRIC: 'NRIC',
        USERNAME: 'Username',
        ENABLED: 'Enabled',
        REGISTRATIONDATE: 'Register Date',
        CREATEDATE: 'Create Date',
        REFERDATE: 'Refer Date',
        STATUS: 'Status',
        PHONE: 'Phone',
        EMAIL: 'Email',
        REQUESTID: 'Request ID',
        ORDERID: 'Order ID',
        REFUNDREQUESTID: 'Refund Request ID',
        TIME: 'Time',
        REQUESTDATE: 'Request Date',
        CURRENCY: 'Currency',
        DESCRIPTION: 'Description',
        RFUNDAMOUNT: 'Refund Amount',
        DEPOSIT_TITLE: 'Deposit Refund Request ID: ',
        HISTORY: 'History',
        USER_DETAIL: 'User Details',
        DEPOSIT_DETAIL: 'Deposit Details',
        AMOUNT: 'Amount',
      },
      TITLE: 'Customer Management',
      REGISTER: 'Register',
      EXPORT: 'Export',
      USER: 'User',
      USERLIST: 'User List',
      ADVANCEDSEARCH: 'Advanced Search',
      DRIVER: 'Named Driver',
      DEPOSIT: 'Deposit Refund Request',
    },
    VEHICLE: {
      GENERAL: {
        BRAND: 'Brand',
        CODE: 'Code',
        NAME: 'Name',
        DESCRIPTION: 'Description',
        COLOUR: 'Colour',
        IMAGE: 'Image',
        CATEGORY: 'Vehicle Category',
        TOTAL: 'Total Number of Vehicles',
        PEAK: 'Peak Hour',
        OFF: 'Off-Peak Hour',
        SUPER: 'Super Off-Peak Hour',
        VEHICLECODE: 'Vehicle Code',
        CARPARKCODE: 'Car Park Code',
        LICENSEPLATE: 'License Plate',
        CARPARKNAME: 'Car Park Name',
        LOCATION: 'Location',
        CLASS: 'Class',
        TYPE: 'Type',
        SEATS: 'Seats',
        STATUS: 'Status ',

      },
      CARPARK: {
        TITLE: 'Car Park',
        DETAIL: 'Car Park Detail',
        ADDRESS: 'Address',
        NO_LOT: 'Number of lots',
        CITY: 'City/State',
        COUNTRY: 'Country',
        POSTCODE: 'Postal Code',
        LATITUDE: 'Latitude',
        LONGITUDE: 'Longitude',
        VEHICLE: 'List of vehicles',
        VEHICLE_NAME: 'Vehicle Name',
        PLATE: 'Plate Number',
        LOT: 'Lot no.',
        DECK: 'Deck no.',
        CARPARK_NO: 'Car Park no.',
      },
      FLEETCARD: {
        IMAGE: 'Image',
        TITLE: 'Fleet Card',
        CARD: 'Card Number',
        NAME: 'Card Name',
        PIN: 'Pin',
        EXPIRED: 'Expired Date (MM/YY)',
        DETAIL: 'Fleet Card Detail',
        HISTORY: 'Fleet Refuel Histories',
        DATE: 'Refuel Date Time',
        STATUS: 'Status',
        PHOTO: 'Photo Key',
        TIME: 'Photo Time',
        PIN_1: 'Reveal Pin 1',
        PIN_2: 'Reveal Pin 2',
        FLEET_PIN: {
          TITLE: 'Fleet Pin',
          DESCRIPTION: 'Description',
          PIN: 'Number',
        },
        ORDER: {
          NO: 'Order No',
          USER_NAME: 'Patron Name',
          VEHICLE_NAME: 'Vehicle Name',
          PLATE: 'Plate No',
          EMAIL: 'Email',
          PHONE: 'Mobile No',
        },
      },
      CATEGORY_TITLE: 'Category',
      CATEGORY_DETAIL: 'Category Detail',
      CATEGORY: 'Category Name',
      PRICE_SUBTITLE: 'Price Rate Details',
      SPECIAL: 'Special Period Price Rate',
      SUPER: 'Super Off-Peak Price',
      OFF: 'Off-Peak Price',
      PEAK: 'Peak Price',
      MANAGE: 'Vehicle Management',
      MODEL: 'Model',
      VEHICLE: 'Vehicle',
      GROOMING: 'Grooming',

      RATE: 'Rate Card Schedule',
      MAINTENANCE: 'Maintenance',
      SCHEDULE: 'Vehicle Schedule',
    },
    DEALER: {
      MANAGEMENT: 'Dealer Management',
      REVENUE_REPORT: 'Revenue Report',
      COMPANIES: 'Company',
      DEPLOYMENTS: 'Deployment',
      SETTLEMENTS: 'Settlement'
    },
    ORDER: {
      TITLE: 'Order List',
      REQUEST: 'Refund Requests',
      TRANSACTION: 'Transaction',
      REAUTH: 'Additional Charge',
      REFUELREQUEST: 'Refuel Refund',
      BLOCK: 'Block Times',
      DATE: 'Order Date',
      TOTAL_MIN: 'Total Minute(s)',
      MANAGEMENT: 'Order Management',
      REFUND: 'Request Refund',
      REFUND_INFO: 'Refund Info',
      STATUS: 'Status',
      REQUESTED: 'Requested Amount',
      REQUESTEDBY: 'Requested By',
      APPROVED: 'Approved Amount',
      APPROVEDBY: 'Approved By',
      TYPE: 'Refund Type',
      REMARK: 'Remarks',
      ORDER_NO: 'Order No',
      USER: 'User Name',
      REFUND_LIST: 'Refund Request List',
      REFUND_DETAIL: 'Refund Request Detail',
      BLOCK_DETAIL: 'Block Time Detail #',
      REQUESTTIME: 'Request Time',
      PROCESSEDTIME: 'Processed Time',
      R_DETAIL: {
        TYPE: 'Refund Type:',
        STATUS: 'Status:',
        R_BY: 'Requested By:',
        R_AT: 'Requested At:',
        REMARK: 'Remarks:',
        REQUESTED: 'Requested Amount:',
        P_BY: 'Processed By:',
        P_AT: 'Processed At:',
        COMMENT: 'Approver Comment:',
        APPROVED: 'Approved Amount:',
        APPROVEDDEDUCT: 'Approved Deduct Amount',
        ORDER_NO: 'Order Detail #',
        V_STATUS: 'Vehicle Status:',
        U_STATUS: 'Patron Status:',
        O_STATUS: 'Order Status:',
        AST: 'Actual Start Time:',
        AET: 'Actual End Time:',
        U_NAME: 'Patron Name:',
        V_NAME: 'Vehicle Name:',
        MOBILE: 'Mobile No:',
        EMAIL: 'Email: ',
        MODAL_TITLE: 'Processing Refund',
        PROCESS_DETAIL: 'Process Detail',
        NO_PROCESS: 'This request is pending to be process.',
      },
      B_DETAIL: {
        VEHICLE: 'Vehicle Name:',
        IU: 'In-Vehicle Unit:',
        CARPARK: 'Carpark Name:',
        MODEL: 'Model Name:',
        CATEGORY: 'Category Name:',
        ORDER: 'Order No:',
        USER: 'Patron Name:',
        EMAIL: 'Patron Email:',
        BOOKED: 'Booked Date Time',
      },
    },
    SETTING: {
      TITLE: 'Setting',
      COMPANY: 'Company',
      ADMIN: 'Admin',
      ROLE: 'Role',
      HISTORY: 'History',
      RATE_CARD: 'Rate Card',
      RATE_CARD_PERIOD: 'Rate Card Period',
      RATE_CARD_DETAIL: 'Rate Card Detail',
      RATE_CREATED: 'Created Date Time',
      PUBLIC_HOLIDAY: {
        TITLE_YEAR: 'Public Holidays',
        TITLE_ADD: 'Adding New Holiday',
        TITLE: 'Public Holiday Detail',
        NAME: 'Holiday Name',
        START: 'Start Date',
        END: 'End Date',
      },
      POPUP: {
        TABLE: {
          NAME: 'Pop-Up Name',
          LINK: 'Link',
          IMAGE: 'Primary Image',
          STATUS: 'Status',
          START: 'Start Date',
          END: 'End Date',
        },
        DETAIL: 'Popup Detail',
        DATE: 'Popup Advertisement Date',
      },
    },
    TRACKERS: {
      TITLE: 'Trackers',
      NAME: 'Device Name',
      MODEL: 'Model',
      TYPE: 'Type',
      VERSION: 'Version No.',
      PHONE: 'Phone No',
      STATUS: 'Connection Status',
      PASSWORD: 'Password',
      CCID: 'Cellular Card ID',
      DETAIL: {
        TITLE: 'Tracker Detail',
        OPERATION_TITLE: 'Tracker Operation Logs',
        PLATE: 'License Plate: ',
        VERSION: 'Version No: ',
        CCID: 'Cellular Card ID: ',
        NAME: 'Device Name: ',
        MODEL: 'Device Model: ',
        PHONE: 'Phone No: ',
        STATUS: 'Connection Status: ',
        PASSWORD: 'Password: ',
        OPERATION: 'Vehicle Operation',
        SUPER: 'Super Password: ',
        CHARGED: 'Charged: ',
        REAL_TIME: 'Tracker Real-Time Status',
        LATITUDE: 'Latitude: ',
        LONGITUDE: 'Longitude: ',
        ALTITUDE: 'Altitude (m): ',
        SPEED: 'Speed (km/h): ',
        DIRECTION: 'Direction: ',
        QUANTITY: 'Quantity: ',
        ENDURANCE: 'Endurance: ',
        MILEAGE: 'Total Mileage: ',
        VOLTAGE: 'Voltage: ',
        GPRS: 'General Packet Radio Service: ',
        TACH: 'Tachometer: ',
        O_TIME: 'Operation Time',
        O_TYPE: 'Operation Type',
        OPERATOR: 'Operator',
        IP: 'IP Address',
      },
    },
    MAP: {
      TITLE: "Map",
      TYPE:"Map Type",
      PLATE: "License Plate",
      CARPARK: "Carpark Map",
      VEHICLE: "Vehicle Map",
    },
    FEEDBACK: {
      TITLE: "Feedback",
    },
    NOTICE: {
      TITLE: "Notice",
    },
    BOOKING: 'Booking',
    PROMOTION: {
      TITLE: 'Promotion Management',
      CHILDREN: {
        VOUCHER: 'Voucher List',
        SPINNER: 'Spinner'
      },
    },
    REPORT: {
      TITLE: 'Reports',
      CHILDREN: {
        QUICK: 'Quick',
        SEARCH: 'Search Log',
        MONTHLY_REPORT: 'Monthly Report',
        FEEDBACK: 'Feedback',
        USER: 'User Report',
        VEHICLE: 'Vehicle Report',
        ORDER: 'Order Report',
        VOUCHER: 'Voucher Report'
      },
    },
    TRACKER: {
      TITLE: 'Tracker Management',
      SHARIOT: 'Shariot Tracker',
      CARTRACK: 'CarTrack Tracker'
    },
    HOLIDAY: 'Public Holiday',
    POPUP: 'Popup Ads',
    // BOOKING: {
    //   TITLE: 'Manage Bookings',
    //   BOOKING: 'Booking',
    //   REFUND: 'Refund Request'
    // },
    // PROMOTION: {
    //   TITLE: 'Manage Promotions',
    //   PROMOTION: 'Promotion',
    //   POPUP: 'Promotion Popup',
    //   TOPUP: 'Promotion Top-Up',
    //   REFERRAL: 'Promotion Referral Code',
    //   CHECKBOX: 'Promotion Checkbox'
    // }
  },
  PROFILE: {
    OVERVIEW: {
      NAME: 'Name',
      EMAIL: 'Email',
      USERNAME: 'Username'
    },
    SECURITY: {
      PASSWORD: 'Password',
      EMAIL: 'Email',
      OTP: 'OTP',
      OLDPASSWORD: 'Old Password',
      NEWPASSWORD: 'New Password',
      CONFIRMPASSWORD: 'Confirm Password',
    }
  },
  DASHBOARD: {
    DATETYPE: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      BEFOREYESTERDAY: 'Before Yesterday',
      THIS_WEEK: 'This Week',
      LAST_WEEK: 'Last Week',
      LAST_7_DAYS: 'Last 7 days',
      LAST_30_DAYS: 'Last 30 days',
      PREVIOUS_30_DAYS: 'Previous 30 days',
      PREVIOUS_7_DAYS: 'PREVIOUS_7_DAYS',
      THIS_MONTH: 'This Month',
      LAST_MONTH: 'Last Month',
      THIS_YEAR: 'This Year',
      LAST_YEAR: 'Last Year',
      MONTH_YEAR: '{{month}} {{year}}',
      CUSTOM: 'Custom',
    },
  },
  USER: {
    INDEX: {},
    DETAIL: {
      PAGETITLE: 'User Info',
      POINTLOG: {
        TABLE: {
          DATE: 'Date',
          ORDER: 'Order No',
          DESCRIPTION: 'Description',
          MESSAGE: 'Message (Write to CX)',
          IN: 'In',
          OUT: 'Out',
          BALANCE: 'Balance'
        }
      }
    },
  },
	DEPOSITREFUND: {
		INDEX: {
			TABLE: {
				REQUESTID: 'Request ID',
				USER: 'User Name',
				STATUS: 'Status',
				PREVIOUSTYPE: 'Previous type',
				CURRENTTYPE: 'Current Type',
				LASTDEPOSIT: 'Last Deposit',
				NEWDEPOSIT: 'New Deposit',
				REFUNDAMOUNT: 'Refund Amount',
				REASON: 'Reason',
				REQUESTDATE: 'Request Date',
				NRIC: 'Nric',
				ISPAID: 'Is Paid',
				TIME: 'Time'
			}
		},
		DETAIL: {
			PAGETITLE: 'User Info',
      REASON: {
        "haveBadExperience": "Have bad experience",
        "isTooTroublesome": "Too troublesome",
        "isNoLongerNeeded": "No longer needed",
        "isTooLimited": "Too limited",
        "isOutOfReach": "Out of reach",
        "other": "Other"
      }
		},
	},
  MODEL: {
    INDEX: {
      TABLE: {
        BRAND: 'Brand',
        CODE: 'Code',
        NAME: 'Name',
        DESCRIPTION: 'Description',
        COLOUR: 'Colour',
        IMAGE: 'Image',
        VEHICLENUMBER: 'Vehicle Number',
      },
    },
    DETAIL: {
      PAGETITLE: 'Model Detail #{{name}}',
    },
  },
  ORDER: {
    PROBLEM: {
      end_trip_photo_is_damaged_vehicle: 'End Trip Photo is damaged',
      start_trip_photo_is_damaged_vehicle: 'Start Trip Photo is damaged',
      selfie: 'Selfie'
    },
    DETAIL: {
      PAGETITLE: 'Order Detail #{{order_no}}',
      REAUTH: 'Additional Charge',
      REFUND: 'Refund',
      HISTORIES: 'Histories'
    },
    INDEX: {
      TABLE: {
        STATUS: 'Status',
        ORDERNO: 'Order No',
        CUSTOMERNAME: 'Customer Name',
        EMAIL: 'Email',
        VEHICLENAME: 'Vehicle Name',
        LICENSEPLATE: 'License Plate',
        STARTTIME: 'Start Time',
        ACTUALSTARTTIME: 'Actual Start Time',
        ENDTIME: 'End Time',
        ACTUALENDTIME: 'Actual End Time',
        DURATION: 'Duration',
        EXTENDTRIPCOUNT: 'Extend Trip Number',
      },
    },
  },
  TRANSACTION: {
    INDEX: {
      TABLE: {
        STATUS: 'Status',
        ORDERNO: 'Order No',
        AMOUNT: 'Amount',
        TYPE: 'Type',
        DESCRIPTION: 'Description',
        DATE: 'Time'
      }
    }
  },
  DEALER: {
    YEAR: 'Year',
    MONTH: 'Month',
  },
  REPORT: {
    QUICK: {
      INDEX: {
        TITLE: 'Quick',
        TABLE: {
          START_DATE: 'Start Date',
          END_DATE: 'End Date',
          NAME: 'Name',
          EMAIL: 'Email',
          NRIC: 'Nric',
          PHONE: 'Phone Number',
          CATEGORY: 'Category',
          MODEL: 'Model',
          PLATENUMBER: 'Plate Number',
          VOUCHERCODE: 'Voucher Code',
          ORDERSTATUS: 'Order Status',
          CARPARKLOCATION: 'Carpark location',
          ISDEALER: 'Is Dealer',
          VEHICLE: 'Vehicle',
          nric: 'NRIC',
          mobile_no: 'Phone Number',
          email: 'Email',
          users_count: 'Total User',
          vehicles_count: 'Total Vehicle',
          total_duration: 'Total Duration',
          orders_order_no: 'Order No',
          orders_status: 'Order Status',
          orders_start_time: 'Start Time',
          orders_end_time: 'End Time',
          users_name: 'User Name',
          users_email: 'User Email',
          users_nric: 'User Nric',
          vehicles_name: 'Vehicle Name',
          vehicles_plate_no: 'Vehicle Plate No',
          vehicle_categories_name: 'Vehicle Category Name',
          vehicle_models_name: 'Vehicle Model Name',
          carpark_locations_name: 'Carpark Location Name',
          subtotal_paid: 'Subtotal Paid',
          total_minute: 'Total Minute',
          total_paid: 'Total Paid',
          total_promotion_discount_amount: 'Total Promotion Discount Amount',
          total_store_discount_amount: 'Total Store Discount Amount',
          total_rental: 'Total Rental',
          total_cdw: 'Total Cdw',
          total_mte: 'Total Mte',
          total_gst: 'Total Gst',
          total_mileage_charge: 'Total Mileage Charge',
          total_penalty_charge: 'Total Penalty Charge',
          total_refund: 'Total Refund',
          total_cancel_charge: 'Total Cancel Charge',
          total_additional_charges: 'Total Additional Charge',
          total_dealer_revenue: 'Total Dealer Revenue',
          total_amount: 'Total Amount',
          vouchers_code: 'Voucher Code',
          GROUP: {
            INDEX: 'Group By',
            USER: 'User',
            EMAIL: 'Email',
            VEHICLE: 'Vehicle',
            CATEGORY: 'Category',
            MODEL: 'Model',
            PLATENO: 'Plate No',
            VOUCHERCODE: 'Voucher Code',
            ORDERSTATUS: 'Order Status',
            CARPARK: 'CarPark',
          },
        },
      },
    },
    ORDER: {
      INDEX: {
        DATA1: 'Data 1',
        DATA2: 'Data 2',
      },
    },
    FEEDBACK: {
      TITLE:'Feedback',
      INDEX:{
        DATE:'Feedback Date',
        NAME:'Name',
        TYPE:'Type',
        END: 'END TRIP',
        CLOSE: 'CLOSE ACCOUNT',
        NEW: 'New',
        ASSIGN: 'Assigned',
        COMPLETE: 'Completed',
        NA: 'Not Available',
        FEED: 'FEEDBACK',
        A_BY: 'Assigner',
        A_TO: 'Assignee'
      },
      TABLE:{
        rating: 'Rating',
        others: 'Others',
        status: 'Status'
      },
      DETAIL:{
        TITLE:'Feedback Detail',
        TYPE:'Type',
        STATUS:'Status',
        FEEDBACK_DATE: 'Feedback Submitted On',
        CATEGORY:'Category',
        OTHER:`Others' Detail`,
        COMPLETE_AT: 'Completed On',
        REMARK: 'Remarks',
        USER: {
          TITLE:'User',
          NAME:'Name',
          EMAIL:'Email',
          MOBILE:'Mobile NO',
          LANGUAGE: 'Preffered Language'
        },
        ORDER: {
          TITLE: 'Order',
          TOTAL: 'Booking Total Amount',
          RATING: 'Rating',
          V_NAME:'Vehicle Name',
          PLATE:'Plate NO'
        }
      }
    },
    VEHICLE: {
      INDEX: {
        TABLE: {
          VEHICLE: 'Vehicle',
          CATEGORY: 'Category',
          MODEL: 'Model Name',
          UTILIZATION: 'Utilization Hour',
          DURATION: 'Total Duration',
          AVERAGE: 'Average'
        }
      },
    }
  },
  SETTING: {
    ADMIN: {
      INDEX: {
        TITLE: 'Admin',
      },
      DETAIL: {
        ROLES: {
          ADMIN: 'Admin',
          SUPERADMIN: 'Super Admin',
          DEALER: 'Dealer',
          PAGES: {
            DASHBOARD: 'Dashboard',
            ADMINS: 'Admin',
            CARPARKS: 'Carpark',
            CARPARK_LOCATIONS: 'Carpark Location',
            ORDERS: 'Order',
            USERS: 'User',
            USER_DEPOSITS: 'User Deposit',
            USER_REFEREES: 'User Referees',
            USER_REGISTRATION_HISTORIES: 'User Registration History',
            USER_WALLET_HISTORIES: 'User wallet histories',
            VEHICLES: 'Vehicle',
            VEHICLE_CATEGORIES: 'Vehicle Category',
            VEHICLE_MODEL_BRANDS: 'Vehicle Model Brand',
            VEHICLE_MODELS: 'Vehicle Model',
            VOUCHERS: 'Voucher',
            TRACKERS: 'Tracker',
            TRACKER_CONFIGS: 'Tracker Config',
            TRACKER_OPERATION_LOGS: 'Tracker Operation Log',
            TRACKER_STATUS_LOGS: 'Tracker Status Log',
            REPORT_ORDERS: 'Report Order',
            REPORT_FEEDBACKS: 'Report Feedback',
            REPORT_QUICK: 'Report Quick',
            REPORT_USERS: 'Report User',
            REPORT_VEHICLES: 'Report Vehicle',
            REPORT_VOUCHERS: 'Report Voucher',
            REPORT_MONTHLY: 'Report Monthly',
            REPORT_SEARCH: 'Report Search Log',
            SETTING_HOLIDAYS: 'Setting Holiday',
            SETTING_MEMBERSHIP_TIERS: 'Setting Membership Tier',
            SETTING_POPUPS: 'Setting Pop Up',
            DEPOSIT_REFUNDS: 'Deposit Refund',
            REAUTH_REQUESTS: 'Reauth Request',
            REFUND_REQUESTS: 'Refund Request',
            FLEET_CARDS: 'Fleet Card',
            SETTING_COMPANIES: 'Setting Company',
            SETTING_ADMINS: 'Setting Admin',
            SETTING_ROLES: 'Setting Role',
            SETTING_PERIODS: 'Setting Period',
            SETTING_HISTORIES: 'Setting History',
            GROOMING: 'Grooming',
            DEALERS: 'Dealer',
            FEEDBACKS: 'Feedback',
            MAP: 'Map',
            COMPANIES: 'Company',
            SPINNER_HISTORIES: 'Spinner History',
            NOTICES: 'Notice',
            SETTING_SETTINGS: 'Setting',
            SHARIOT_TRACKERS: 'Shariot Tracker',
            CARTRACK_TRACKERS: 'CarTrack Tracker',
            VEHICLE_EXPENSES: 'Vehicle Expenses',
            NOTIFICATION: 'Notification',
            VINCENT_DASHBOARD: 'Vincent Dashboard',
            COSTING: 'Costing',
            ACCOUNT_INVOICES: 'Account Invoice',
            ACCOUNT_REPORTS: 'Account Report'
          },
        },
        FORM: {
          NAME: 'Name',
          USERNAME: 'Username',
          PASSWORD: 'Password',
          MOBILEPREFIX: 'Mobile Prefix',
          MOBILENO: 'Mobile No',
          EMAIL: 'Email',
          ENABLED: 'Enabled',
          ROLE: 'Role',
          PHONE: 'Phone',
          MFA: 'Google Authenticator',
        },
      },
    },
    ROLE: {
      INDEX: {
        TITLE: 'Role',
        TABLE: {
          NAME: 'Name',
          DISPLAYNAME: 'Display Name',
          DESCRIPTION: 'Description',
          CREATEDATE: 'Create Time',
          USERCOUNT: 'Number of involved person',
        },
      },
      DETAIL: {
        FORM: {
          NAME: 'Name',
          DISPLAYNAME: 'Display Name',
          DESCRIPTION: 'Description',
        },
      },
    },
    HISTORY: {
      INDEX: {
        TITLE: 'History',
        TABLE: {
          EVENT: 'Event',
          GUARDABLETYPE: 'Guard Type',
          GUARDABLENAME: 'Guard Name',
          GUARDABLEVALUE: 'Guard Value',
          ITEMABLETYPE: 'Item Type',
          ITEMABLEVALUE: 'Item Value',
          URL: 'Url',
          IP: 'IP',
          USERAGENT: 'User Agent',
          CREATEDATE: 'Create Time',
        },
      },
    },
    SETTING: {
      TYPE: {
        0: 'Boolean',
        1: 'Decimal',
        2: 'Integer',
        3: 'String',
      }
    }
  },
  VOUCHER: {
    TITLE: 'Voucher',
    PUBLICITY: {
      PUBLIC: 'Public',
      PRIVATE: 'Private',
    },
    INDEX: {
      TABLE: {
        CODE: 'Promo Code',
        TYPE: 'Type',
        DESCRIPTION: 'Description',
        AMOUNT: 'Amount',
        STARTDATE: 'Start Date',
        ENDDATE: 'End Date',
        ENABLED: 'Enabled',
        QUANTITY: 'Quantity',
        PUBLICITY: 'Publicity',
      },
    },
    DETAIL: {
      PAGETITLE: 'Voucher Detail',
      FORM: {
        CODE: 'code',
        PUBLICITY: 'publicity',
      },
    },
  },
  AUTH: {
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      BUTTON: 'Sign In',
    },
    FORGOT: {
      TITLE: 'Forgotten Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'Username',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
};
