import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '@env/environment'
import { isEmpty } from '@helper/helper'
import { WebSocketService } from '@helper/websocket'
import { ToastService } from '@shared/toast/toast.service'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs'
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators'
import { UserModel } from '../components/auth/models/user.model'
import { AuthHTTPService } from './auth-http'

export type UserType = UserModel | undefined

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	// private fields
	private unsubscribe: Subscription[] = [] // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	private authLocalStorageToken = `${environment.USERDATA_KEY}`

	// public fields
	currentUser$: Observable<UserType>
	currentUserSubject: BehaviorSubject<UserType>

	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	private isOTPLoadingSubject = new BehaviorSubject<boolean>(false)
	public isOTPLoading$ = this.isOTPLoadingSubject.asObservable()

	get currentUserValue(): UserType {
		return this.currentUserSubject.value
	}

	set currentUserValue(user: UserType) {
		this.currentUserSubject.next(user)
	}

	params: any = {}

	constructor(
		private http: HttpClient,
		private authHttpService: AuthHTTPService,
		private toastService: ToastService,
		private router: Router,
		private wsService: WebSocketService,
		private cookieService: CookieService,
		private route: ActivatedRoute
	) {
		this.currentUserSubject = new BehaviorSubject<UserType>(undefined)
		this.currentUser$ = this.currentUserSubject.asObservable()

		// const subscr = this.getUserByToken().subscribe()
		// this.unsubscribe.push(subscr)
	}

	// public methods
	login(email: string, password: string, country: any = 'sg'): Observable<any> {
		this.isLoadingSubject.next(true)
		return this.authHttpService.login(email, password, country).pipe(
			tap((user: any) => {
				if (user) {
					localStorage.setItem('ws_token', JSON.stringify(user.data?.user?.remember_token))
					this.wsService.setWsToken(user.data?.user.remember_token)
				}
			}),
			finalize(() => {
				this.isLoadingSubject.next(false)
			})
		)
	}

	logout(...args: any) {
		this.currentUserSubject.next(undefined)
		const admin = this.getAuthFromLocalStorage()

		if (admin?.bearerToken) {
			this.authHttpService.logout().subscribe({
				next: () => {},
				error: () => {},
			})
		}

		if (args.find((v: any) => v.expired)) {
			this.toastService.show('Please login again!', {
				delay: 3000,
				autohide: true,
				classname: 'bg-warning text-light',
				headertext: 'Session expired',
			})
		} else if (!args.find((v: any) => v.silent)) {
			this.toastService.show('Bye see you next time!', {
				delay: 3000,
				autohide: true,
				headertext: 'Successfully logout',
			})
		}

		localStorage.removeItem('shariot_redirect_token')
		localStorage.removeItem(this.authLocalStorageToken)

		let allowPages = [
			'/auth/login',
			'/auth/registration',
			'/auth/forgot-password',
			'/auth/reset-password',
			// '/auth/google2fa',
		]

		if (allowPages.includes(window.location.pathname)) {
			return
		}

		this.router.navigate(['/auth/login'])
	}

	getUserByToken(params: any = {}): any {
		return new Promise((resolve, reject) => {
			let user = this.getAuthFromLocalStorage()

			this.isLoadingSubject.next(true)

			let httpHeaders = new HttpHeaders({
				Authorization: `Bearer ${user?.bearerToken}`,
				Accept: 'application/json',
				Country: user?.country || 'sg',
			})

			this.http
				.get<any>(`/me`, {
					headers: httpHeaders,
					params: params,
				})
				.subscribe({
					next: (res: any) => {
						if (res?.data?.user) {
							let user = res?.data?.user

							user = {
								...user,
								mobile_prefix: '+' + user.mobile_prefix,
							}
							this.isLoadingSubject.next(false)
							this.currentUserSubject.next(user)
							this.setAuthFromLocalStorage(user)
						}
						resolve(true)
					},
					error: (err) => {
						console.log(err)
						this.isLoadingSubject.next(false)
						reject(true)
					},
				})
		})
	}

	removeToken() {
		this.currentUserSubject.next(undefined)
		localStorage.removeItem(this.authLocalStorageToken)
	}

	// need create new user then login
	registration(user: UserModel): Observable<any> {
		this.isLoadingSubject.next(true)
		return this.authHttpService.createUser(user).pipe(
			map(() => {
				this.isLoadingSubject.next(false)
			}),
			switchMap(() => this.login(user.email, user.password, user.country)),
			catchError((err) => {
				console.error('err', err)
				return of(undefined)
			}),
			finalize(() => this.isLoadingSubject.next(false))
		)
	}

	forgotPassword(email: string): Observable<boolean> {
		this.isLoadingSubject.next(true)
		return this.authHttpService.forgotPassword(email).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	setAuthFromLocalStorage(user: any): boolean {
		if (user?.bearerToken) {
			this.currentUserSubject.next(user)
			localStorage.setItem(this.authLocalStorageToken, JSON.stringify(user))
			return true
		}
		return false
	}

	public getAuthFromLocalStorage() {
		try {
			const lsValue = localStorage.getItem(this.authLocalStorageToken)
			if (!lsValue) {
				return undefined
			}

			const authData = JSON.parse(lsValue)

			return authData
		} catch (error) {
			console.error(error)
			return undefined
		}
	}

	ngOnDestroy() {
		this.unsubscribe.forEach((sb) => sb.unsubscribe())
	}

	hasRoles(roles: string | string[]): boolean {
		let admin = this.getAuthFromLocalStorage()
		let userRoles = admin?.roles

		if (!isEmpty(userRoles)) {
			userRoles = userRoles.map((v: any) => v.name)
			if (typeof roles === 'string') {
				roles = [roles]
			}

			return roles.some((item) => userRoles.includes(item))
		}
		return false
	}

	hasPermissions(perm: string | string[]): boolean {
		let admin = this.getAuthFromLocalStorage()
		let userPermission = admin?.permissions
		if (isEmpty(userPermission)) {
			return false
		}
		const actionPositions: any = {
			create: 0,
			read: 1,
			update: 2,
			delete: 3,
			export: 4,
		}

		if (typeof perm === 'string') {
			perm = [perm]
		}

		for (const permissionString of perm) {
			let parts = permissionString.split('-')
			let action = parts[0]

			delete parts[0]
			parts = Array.from(parts).filter((v: any) => v)
			const resource = [...parts].join('_').toUpperCase()
			const position = actionPositions[action]

			if (position === undefined) {
				return false
			}

			const resourcePermissions = userPermission[resource]

			if (!resourcePermissions) {
				return false
			}

			if (resourcePermissions[position] <= 0) {
				return false
			}
		}

		return true
	}

	generateGoogle2FAQrcode() {
		this.isLoadingSubject.next(true)
		const url = `2fa`
		return this.http.get(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	verifyGoogle2FAQrcode(params: any = {}) {
		this.isLoadingSubject.next(true)
		const url = `2fa`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updateProfile = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `me/profile`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updatePassword = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `me/password`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updateEmail = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `me/email`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	forgetPassword = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `forget-password`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	resetPassword = (params: any = {}) => {
		this.isLoadingSubject.next(true)
		const url = `reset-password`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	sendResetEmailOTP = (params: any = {}) => {
		this.isOTPLoadingSubject.next(true)
		const url = `me/email/otp`
		return this.http.post(url, params).pipe(finalize(() => this.isOTPLoadingSubject.next(false)))
	}

	register = (params: any) => {
		this.isLoadingSubject.next(true)
		const url = `register`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
