import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'
import { AuthService } from '@services/auth.service'

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
	@Input() histories: any
	@Input() showHeader: boolean = true

	isOpenHistory: boolean = false
	selectedHistory: any = null
	constructor(private cdr: ChangeDetectorRef, public authService: AuthService) {}

	ngOnInit() {
		// this.histories.sort((a: any, b: any) => +new Date(b.created_at) - +new Date(a.created_at))
	}

	openHistoryModal(history: any) {
		this.selectedHistory = history
		this.isOpenHistory = true
		this.cdr.detectChanges()
	}

	closeModal(e: any) {
		this.selectedHistory = null
		this.isOpenHistory = false
		this.cdr.detectChanges()
	}
}
