import { Pipe, PipeTransform } from '@angular/core'
import { getTimeZone, isDateTimeFormat } from '@helper/helper'
import { isEmpty } from 'lodash'
import moment from 'moment'

@Pipe({
	name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
	transform(value: any, args: any): any {
		if (isEmpty(value)) {
			return null
		}

		if (value.length == 5 && value.includes(':')) {
			return value
		}

		let timezone = getTimeZone()

		let momentValue = moment.utc(value)

		if (!momentValue.isValid()) {
			return value
		}

		let format = 'DD MMM YYYY hh:mm a'

		if (args?.format) {
			format = args.format
		}

		if (args?.subtract) {
			momentValue.subtract(timezone, 'hours')
		}

		if (isDateTimeFormat(value)) {
			return momentValue.format(format)
		}

		return value
	}
}
