// prettier-ignore
export default {
	TRANSLATOR: {
		SELECT: 'Select your language',
	},
	MONTH: {
		JAN: '一月',
		FEB: '二月',
		MAR: '三月',
		APR: '四月',
		MAY: '五月',
		JUN: '六月',
		JUL: '七月',
		AUG: '八月',
		SEP: '九月',
		OCT: '十月',
		NOV: '十一月',
		DEC: '十二月',
	},
	VALIDATION: {
		REQUIRED: '{{field}} 不能为空.',
	},
	STATUS: {
		ACCOUNT_CLOSED: 'Account Closed',
		BLOCKED: 'Blocked',
		REJECTED: 'Rejected',
		DEPOSITED: 'Deposited',
		PENDING_DEPOSIT: 'Pending Deposit',
		VERIFIED: 'Verified',
		PENDING_VERIFICATION: 'Pending Verification',
		RESERVED: 'Reserved',
		UPCOMING: 'Upcoming',
		ONGOING: 'On-going',
		CANCELLED: 'Cancelled',
		COMPLETED: 'Completed',
		RESERVE_CANCELLED: 'Reserve Cancelled',
		ENABLED: 'Enabled',
		DISABLED: 'Disabled',
	},
	ACTION: {
		BLOCK: 'Block',
		UPLOAD: 'Upload',
		RESET: 'Reset',
		SELECTATYPE: 'Please select a search terms',
		SELECTABRAND: 'Please select a brand',
		ADD: 'Add ',
		EXPORT: 'Export',
		SEARCH: 'Search',
		DELETE: 'Delete',
		EDIT: 'Edit',
		SUBMIT: 'Submit',
		REFUNDED: 'Mark as Refunded',
		REJECT: 'Reject',
		APPROVE: 'Approve',
		BACK: 'Back',
		SAVE: 'Save',
		CANCEL: 'Cancel',
		APPLY: 'Apply',
	},
	TABLE: {
		PAGESIZE: 'Page Size',
		PAGE: 'Page',
	},
	MENU: {
		NEW: 'new',
		ACTIONS: 'Actions',
		CREATE_POST: 'Create New Post',
		PAGES: 'Pages',
		FEATURES: 'Features',
		APPS: 'Apps',
		DASHBOARD: '仪表盘',
		REGISTER: {
			GENERAL: {
				TYPE: 'Type',
				REASON: 'Reason',
				TRANSACTIONID: 'Transaction ID',
				MPGSORDERID: 'Mpgs Order ID',
				SELFTRANSACTIONID: 'Self Transaction ID',
				SELECTATYPE: 'Select a search terms',
				NAME: 'Name',
				NRIC: 'NRIC',
				REGISTRATIONDATE: 'Register Date',
				STATUS: 'Status',
				PHONE: 'Phone',
				EMAIL: 'Email',
				REQUESTID: 'Request ID',
				ORDERID: 'Order ID',
				REFUNDREQUESTID: 'Refund Request ID',
				TIME: 'Time',
				REQUESTDATE: 'Request Date',
				CURRENCY: 'Currency',
				DESCRIPTION: 'Description',
				RFUNDAMOUNT: 'Refund Amount',
				DEPOSIT_TITLE: 'Deposit Refund Request ID: ',
				HISTORY: 'History',
				USER_DETAIL: 'User Details',
				DEPOSIT_DETAIL: 'Deposit Details',
				AMOUNT: 'Amount',
			},
			TITLE: '用户管理',
			REGISTER: 'Register',
			EXPORT: 'Export CSV',
			USER: '用户',
			USERLIST: '用户列表',
			ADVANCEDSEARCH: 'Advanced Search',
			DRIVER: 'Named Driver',
			DEPOSIT: 'Deposit Refund Request',
		},
		VEHICLE: {
			GENERAL: {
				BRAND: 'Brand',
				CODE: 'Code',
				NAME: 'Name',
				DESCRIPTION: 'Description',
				COLOUR: 'Colour',
				IMAGE: 'Image',
				CATEGORY: 'Vehicle Category',
				TOTAL: 'Total Number of Vehicles',
				PEAK: 'Peak Hour',
				OFF: 'Off-Peak Hour',
				SUPER: 'Super Off-Peak Hour',
				VEHICLECODE: 'Vehicle Code',
				CARPARKCODE: 'Car Park Code',
				LICENSEPLATE: 'License Plate',
				CARPARKNAME: 'Car Park Name',
				LOCATION: 'Location',
				CLASS: 'Class',
				TYPE: 'Type',
				SEATS: 'Seats',
			},
			CARPARK: {
				TITLE: 'Car Park',
				DETAIL: 'Car Park Detail',
				ADDRESS: 'Address',
				NO_LOT: 'Number of lots',
				CITY: 'City/State',
				COUNTRY: 'Country',
				POSTCODE: 'Postal Code',
				VEHICLE: 'List of vehicles',
				VEHICLE_NAME: 'Vehicle Name',
				PLATE: 'Plate Number',
				LOT: 'Lot no.',
				DECK: 'Deck no.',
				CARPARK_NO: 'Car Park no.',
			},
			CATEGORY_TITLE: 'Category',
			CATEGORY_DETAIL: 'Category Detail',
			CATEGORY: 'Category Name',
			PRICE_SUBTITLE: 'Price Rate Details',
			SPECIAL: 'Special Period Price Rate',
			SUPER: 'Super Off-Peak Price',
			OFF: 'Off-Peak Price',
			PEAK: 'Peak Price',
			MANAGE: 'Vehicle Management',
			MODEL: 'Model',
			VEHICLE: 'Vehicle',
			RATE: 'Rate Card Schedule',
			MAINTENANCE: 'Maintenance',
			SCHEDULE: 'Vehicle Schedule',
		},
		ORDER: {
			TITLE: 'Order',
		},
		SETTING: {
			TITLE: 'Setting',
			RATE_CARD: 'Rate Card',
			RATE_CARD_DETAIL: 'Rate Card Detail',
			PUBLIC_HOLIDAY: {
				TITLE_YEAR: 'Public Holiday of the Year ',
				TITLE_ADD: 'Adding New Holiday',
				TITLE: 'Public Holiday Detail',
				NAME: 'Holiday Name',
				START: 'Start Date',
				END: 'End Date',
			},
			POPUP: {
				TABLE: {
					NAME: 'Pop-Up Name',
					IMAGE: 'Primary Image',
					STATUS: 'Status',
					START: 'Start Date',
					END: 'End Date',
				},
				DETAIL: 'Popup Detail',
				DATE: 'Popup Advertisement Date',
			},
		},
		BOOKING: 'Booking',
		PROMOTION: {
			TITLE: 'Promotion Management',
			CHILDREN: {
				VOUCHER: 'Voucher List',
			},
		},
		TRACKER: 'Manage Trackers',
		HOLIDAY: 'Public Holiday',
		POPUP: 'Popup Ads',
		REPORT: 'Reports',
		// BOOKING: {
		//   TITLE: 'Manage Bookings',
		//   BOOKING: 'Booking',
		//   REFUND: 'Refund Request'
		// },
		// PROMOTION: {
		//   TITLE: 'Manage Promotions',
		//   PROMOTION: 'Promotion',
		//   POPUP: 'Promotion Popup',
		//   TOPUP: 'Promotion Top-Up',
		//   REFERRAL: 'Promotion Referral Code',
		//   CHECKBOX: 'Promotion Checkbox'
		// }
	},
	USER: {
		INDEX: {},
		DETAIL: {
			PAGETITLE: 'User Info',
		},
	},
	REQUESTREFUND: {
		INDEX: {
			TABLE: {
				REQUESTID: 'Request ID',
				USER: 'User Name',
				STATUS: 'Status',
				PREVIOUSTYPE: 'Previous type',
				CURRENTTYPE: 'Current Type',
				LASTDEPOSIT: 'Last Deposit',
				NEWDEPOSIT: 'New Deposit',
				REFUNDAMOUNT: 'Refund Amount',
				REASON: 'Reason',
				REQUESTDATE: 'Request Date',
				NRIC: 'Nric',
				ISPAID: 'Is Paid',
				TIME: 'Time'
			}
		},
		DETAIL: {
			PAGETITLE: 'User Info',
		},
	},
	MODEL: {
		INDEX: {
			TABLE: {
				BRAND: 'Brand',
				CODE: 'Code',
				NAME: 'Name',
				DESCRIPTION: 'Description',
				COLOUR: 'Colour',
				IMAGE: 'Image',
				VEHICLENUMBER: 'Vehicle Number',
			},
		},
		DETAIL: {
			PAGETITLE: 'Model Detail #{{name}}',
		},
	},
	ORDER: {
		DETAIL: {
			PAGETITLE: 'Order Detail #{{order_no}}',
		},
		INDEX: {
			TABLE: {
				STATUS: 'Status',
				ORDERNO: 'Order No',
				CUSTOMERNAME: 'Customer Name',
				EMAIL: 'Email',
				VEHICLENAME: 'Vehicle Name',
				LICENSEPLATE: 'License Plate',
				STARTTIME: 'Start Time',
				ACTUALSTARTTIME: 'Actual Start Time',
				ENDTIME: 'End Time',
				ACTUALENDTIME: 'Actual End Time',
				DURATION: 'Duration',
			},
		},
	},
	VOUCHER: {
		TITLE: 'Voucher',
		PUBLICITY: {
			PUBLIC: 'Public',
			PRIVATE: 'Private',
		},
		INDEX: {
			TABLE: {
				CODE: 'Promo Code',
				DESCRIPTION: 'Description',
				AMOUNT: 'Amount',
				STARTDATE: 'Start Date',
				ENDDATE: 'End Date',
				ENABLED: 'Enabled',
				QUANTITY: 'Quantity',
				PUBLICITY: 'Publicity',
			},
		},
		DETAIL: {
			PAGETITLE: 'Voucher Detail',
			FORM: {
				CODE: '优惠价代码',
				PUBLICITY: '推广',
			},
		},
	},
	AUTH: {
		GENERAL: {
			OR: 'Or',
			SUBMIT_BUTTON: 'Submit',
			NO_ACCOUNT: "Don't have an account?",
			SIGNUP_BUTTON: 'Sign Up',
			FORGOT_BUTTON: 'Forgot Password',
			BACK_BUTTON: 'Back',
			PRIVACY: 'Privacy',
			LEGAL: 'Legal',
			CONTACT: 'Contact',
		},
		LOGIN: {
			TITLE: 'Login Account',
			BUTTON: 'Sign In',
		},
		FORGOT: {
			TITLE: 'Forgotten Password?',
			DESC: 'Enter your email to reset your password',
			SUCCESS: 'Your account has been successfully reset.',
		},
		REGISTER: {
			TITLE: 'Sign Up',
			DESC: 'Enter your details to create your account',
			SUCCESS: 'Your account has been successfuly registered.',
		},
		INPUT: {
			EMAIL: 'Email',
			FULLNAME: 'Fullname',
			PASSWORD: 'Password',
			CONFIRM_PASSWORD: 'Confirm Password',
			USERNAME: 'Username',
		},
		VALIDATION: {
			INVALID: '{{name}} is not valid',
			REQUIRED: '{{name}} is required',
			MIN_LENGTH: '{{name}} minimum length is {{min}}',
			AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
			NOT_FOUND: 'The requested {{name}} is not found',
			INVALID_LOGIN: 'The login detail is incorrect',
			REQUIRED_FIELD: 'Required field',
			MIN_LENGTH_FIELD: 'Minimum field length:',
			MAX_LENGTH_FIELD: 'Maximum field length:',
			INVALID_FIELD: 'Field is not valid',
		},
	},
	ECOMMERCE: {
		COMMON: {
			SELECTED_RECORDS_COUNT: 'Selected records count: ',
			ALL: 'All',
			SUSPENDED: 'Suspended',
			ACTIVE: 'Active',
			FILTER: 'Filter',
			BY_STATUS: 'by Status',
			BY_TYPE: 'by Type',
			BUSINESS: 'Business',
			INDIVIDUAL: 'Individual',
			SEARCH: 'Search',
			IN_ALL_FIELDS: 'in all fields',
		},
		ECOMMERCE: 'eCommerce',
		CUSTOMERS: {
			CUSTOMERS: 'Customers',
			CUSTOMERS_LIST: 'Customers list',
			NEW_CUSTOMER: 'New Customer',
			DELETE_CUSTOMER_SIMPLE: {
				TITLE: 'Customer Delete',
				DESCRIPTION: 'Are you sure to permanently delete this customer?',
				WAIT_DESCRIPTION: 'Customer is deleting...',
				MESSAGE: 'Customer has been deleted',
			},
			DELETE_CUSTOMER_MULTY: {
				TITLE: 'Customers Delete',
				DESCRIPTION: 'Are you sure to permanently delete selected customers?',
				WAIT_DESCRIPTION: 'Customers are deleting...',
				MESSAGE: 'Selected customers have been deleted',
			},
			UPDATE_STATUS: {
				TITLE: 'Status has been updated for selected customers',
				MESSAGE: 'Selected customers status have successfully been updated',
			},
			EDIT: {
				UPDATE_MESSAGE: 'Customer has been updated',
				ADD_MESSAGE: 'Customer has been created',
			},
		},
	},
}
