// custom-form-control.service.ts
import { Injectable } from '@angular/core'
import { FormControl } from '@angular/forms'
import { CustomFormControl } from '@shared/form/form-control/form-control.component'

@Injectable({
	providedIn: 'root',
})
export class CustomFormControlService {
	private formControlMap = new Map<FormControl, CustomFormControl>()

	registerFormControl(fc: FormControl, component: CustomFormControl): void {
		this.formControlMap.set(fc, component)
	}

	getComponent(fc: FormControl): CustomFormControl | undefined {
		return this.formControlMap.get(fc)
	}
}
