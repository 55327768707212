import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { isEmpty } from '@helper/helper'
// import crypto from 'crypto'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import Swal from 'sweetalert2'
@Injectable({
	providedIn: 'root',
})
export class TabStore {
	private readonly _key = 'tabStore'

	private _allData = new BehaviorSubject<any>({
		tasks: {
			lastKey: null,
			tabs: [],
		},
		['account-invoices']: {
			lastKey: null,
			tabs: [],
		},
		['account-reports']: {
			lastKey: null,
			tabs: [],
		},
		users: {
			lastKey: null,
			tabs: [],
		},
		['deposit-refunds']: {
			lastKey: null,
			tabs: [],
		},
		['vehicle-models']: {
			lastKey: null,
			tabs: [],
		},
		grooming: {
			lastKey: null,
			tabs: [],
		},
		['carparks']: {
			lastKey: null,
			tabs: [],
		},
		['fleet-cards']: {
			lastKey: null,
			tabs: [],
		},
		['revenue-report']: {
			lastKey: null,
			tabs: [],
		},
		orders: {
			lastKey: null,
			tabs: [],
		},
		reauths: {
			lastKey: null,
			tabs: [],
		},
		refunds: {
			lastKey: null,
			tabs: [],
		},
		['block-times']: {
			lastKey: null,
			tabs: [],
		},
		vouchers: {
			lastKey: null,
			tabs: [],
		},
		['spinner-histories']: {
			lastKey: null,
			tabs: [],
		},
		feedbacks: {
			lastKey: null,
			tabs: [],
		},
		vehicles: {
			lastKey: null,
			tabs: [],
		},
		vehicleModels: {
			lastKey: null,
			tabs: [],
		},
		vehicleCategories: {
			lastKey: null,
			tabs: [],
		},
		['dealer-companies']: {
			lastKey: null,
			tabs: [],
		},
		['dealer-deployments']: {
			lastKey: null,
			tabs: [],
		},
		['dealer-settlements']: {
			lastKey: null,
			tabs: [],
		},
		['dealer-vehicles']: {
			lastKey: null,
			tabs: [],
		},
		['setting-dealers']: {
			lastKey: null,
			tabs: [],
		},
		['setting-companies']: {
			lastKey: null,
			tabs: [],
		},
		['reports-overview']: {
			lastKey: null,
			tabs: [],
		},
		['vehicle-expenses']: {
			lastKey: null,
			tabs: [],
		},
		notices: {
			lastKey: null,
			tabs: [],
		},

		settings: {
			lastKey: null,
			tabs: [],
		},

		['reports-settlements']: {
			lastKey: null,
			tabs: [],
		},
		['cartrack-trackers']: {
			lastKey: null,
			tabs: [],
		},
		['report-vehicle-status']: {
			lastKey: null,
			tabs: [],
		},
		['report-vehicle-duration']: {
			lastKey: null,
			tabs: [],
		},
		['report-voucher']: {
			lastKey: null,
			tabs: [],
		},
	})

	private _keySubject = new BehaviorSubject<string>('')
	private _paramsSubject = new BehaviorSubject<any>({})
	private _selectedTabSubject = new BehaviorSubject<any>('all')

	allData$: Observable<any> = this._allData.asObservable()
	key$: Observable<string> = this._keySubject.asObservable()
	params$: Observable<any> = this._paramsSubject.asObservable()
	selectedTab$: Observable<any> = this._selectedTabSubject.asObservable()

	userKey = environment.USERDATA_KEY

	initialize(key: string) {
		this._keySubject.next(key)
		const currentData = { ...this._allData.value }
		const tabs: any = []

		if (!currentData[this._keySubject.value]) {
			return
		}

		tabs.push({ name: 'All', key: 'all', editable: false, isSaved: false, params: {}, data: [] })

		let allTabs = this.getFromLocalStorage()

		if (!isEmpty(allTabs?.tabs)) {
			let all = allTabs.tabs.find((v: any) => v.key == 'all')

			if (all) {
				let match = tabs.find((v: any) => v.key == 'all')
				match.data = all.data
			}

			let savedTabs = allTabs.tabs.filter((v: any) => v.isSaved && v.key !== 'all')

			if (savedTabs.length) {
				savedTabs.forEach((item: any) => {
					tabs.push(item)
				})
			}
		}

		currentData[this._keySubject.value].tabs = tabs
		this._allData.next(currentData)
	}

	data$: Observable<any> = this.allData$.pipe(map((data) => data[this._keySubject.value]))

	saveParams(params: any) {
		let currentData = { ...this._allData.value }

		if (!this._keySubject.value) {
			return
		}

		if (!currentData[this._keySubject.value]) {
			return
		}

		let tabs = currentData[this._keySubject.value]?.tabs

		let selected = tabs.find((v: any) => v.key == this._selectedTabSubject.value)
		if (selected) {
			selected.params = params

			if (selected.isSaved) {
				this.saveTab(selected.key, selected.isSaved)
			}
		}
		this._allData.next(currentData)
	}

	addTab() {
		if (isEmpty(this._keySubject.value)) {
			return
		}

		const currentData = { ...this._allData.value }
		const tabs = currentData[this._keySubject.value]?.tabs || []

		let key = Math.floor(Date.now() / 1000)

		if (tabs.length > 9) {
			Swal.fire({
				title: 'System Reminder',
				text: 'Quantity of table tabs too many, please remove some tabs',
				icon: 'warning',
				showCancelButton: false,
				allowOutsideClick: false,
			})
			return
		}

		tabs.push({ name: 'Result', key: `${key}`, editable: false, isSaved: false, params: {} })
		currentData[this._keySubject.value].tabs = tabs
		this._allData.next(currentData)
		// this.selectTab(key)

		return key
	}

	selectTab(tabKey: string) {
		let currentData = { ...this._allData.value }
		let selected: any = currentData[this._keySubject.value].tabs.find((v: any) => v.key == tabKey)

		if (selected) {
			this._selectedTabSubject.next(selected.key)
			this._paramsSubject.next(selected.params)
		}
	}

	saveTab(tabKey: string, isSaved: boolean) {
		let currentData = { ...this._allData.value }

		let index = currentData[this._keySubject.value].tabs.findIndex((tab: any) => tab.key === tabKey)

		currentData[this._keySubject.value].tabs[index].isSaved = isSaved
		this._allData.next(currentData)
		this.saveToLocalStorage()
	}

	removeTab(tabKey: string) {
		let currentData = { ...this._allData.value }
		let index = currentData[this._keySubject.value].tabs.findIndex((tab: any) => tab.key === tabKey)

		if (index > -1) {
			currentData[this._keySubject.value].tabs.splice(index, 1)
		}

		this._allData.next(currentData)
		this.saveToLocalStorage()
	}

	destroy() {
		if (this._keySubject.value) {
			let currentData = { ...this._allData.value }
			let tabs = currentData[this._keySubject.value]?.tabs

			currentData[this._keySubject.value].tabs = currentData[this._keySubject.value].tabs.filter(
				(v: any) => v.isSaved || v.key == 'all'
			)

			this._allData.next(currentData)
			this.saveToLocalStorage()
		}
	}

	saveToLocalStorage = () => {
		let user = this.getCurrentUser()
		if (user?.uuid) {
			let key = user.uuid + '-' + this._key + '-' + this._keySubject.value
			let savedValue = this._allData.value[this._keySubject.value]
			localStorage.setItem(key, JSON.stringify(savedValue))
		}
	}

	getFromLocalStorage = () => {
		let user = this.getCurrentUser()
		if (user?.uuid) {
			let key = user.uuid + '-' + this._key + '-' + this._keySubject.value
			let value: any = localStorage.getItem(key)
			return JSON.parse(value)
		}
	}

	getCurrentUser() {
		let value: any = localStorage.getItem(this.userKey)
		return JSON.parse(value)
	}
}
