import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class RoleService {
	constructor(private http: HttpClient) {}
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	getRoleListData = (params: any = {}): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/roles`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	getRoleDetail = (uuid: string): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/roles/${uuid}`
		return this.http.get(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	createRole = (params: any): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/roles/`
		return this.http.post(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	updateRole = (uuid: string, params: any): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/roles/${uuid}`
		return this.http.put(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}

	deleteRole = (uuid: string): Observable<any> => {
		this.isLoadingSubject.next(true)
		const url = `settings/roles/${uuid}`
		return this.http.delete(url).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
