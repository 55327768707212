import { getPlatform } from '@helper/helper'

let domainKey = 'shariotPlatform'

let domain = 'admin'

let platform = getPlatform()

if (platform == 'dealer') {
	domain = 'dealer'
}

try {
	localStorage.setItem(domainKey, domain)
} catch (e) {
	console.log(e)
}
