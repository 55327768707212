// app-scroll.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
	selector: '[appScroll]',
})
export class ScrollDirective {
	private isDown = false
	private startX: any
	private scrollLeft: any

	constructor(private el: ElementRef) {}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent): void {
		this.isDown = true
		this.el.nativeElement.classList.add('active')
		this.startX = event.pageX - this.el.nativeElement.offsetLeft
		this.scrollLeft = this.el.nativeElement.scrollLeft
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this.isDown = false
		this.el.nativeElement.classList.remove('active')
	}

	@HostListener('mouseup')
	onMouseUp(): void {
		this.isDown = false
		this.el.nativeElement.classList.remove('active')
	}

	@HostListener('mousemove', ['$event'])
	onMouseMove(event: MouseEvent): void {
		if (!this.isDown) return
		event.preventDefault()
		const x = event.pageX - this.el.nativeElement.offsetLeft
		const walk = (x - this.startX) * 2 // Adjust scroll speed
		this.el.nativeElement.scrollLeft = this.scrollLeft - walk
	}
}
