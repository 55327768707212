import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class PreventClickjackingGuard {
	constructor(private router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let params: any = next.queryParams

		if (window === window.top) {
			return true
		} else {
			return false
		}
	}
}
