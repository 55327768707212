import { isEmpty } from 'lodash'

let url = 'https://api.ipify.org?format=json'
let ipAddress = localStorage.getItem('ipAddress')

if (isEmpty(ipAddress)) {
	fetch(url, {
		method: 'GET',
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}
			return response.json()
		})
		.then((data) => {
			if (data?.ip) {
				localStorage.setItem('ipAddress', data.ip)
			}
		})
		.catch((error) => {
			console.error('Fetch error:', error)
		})
}
