import { DatePipe } from '@angular/common'
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { formatPrice, getTimeZone, isDateTimeFormat, isEmpty } from '@helper/helper'
import { TranslateService } from '@ngx-translate/core'
import { ToastService } from '@shared/toast/toast.service'
import { TabStore } from '@stores/tab.store'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { distinctUntilChanged } from 'rxjs/operators'

@Component({
	selector: 'app-advanced-search',
	templateUrl: './advanced-search.component.html',
	styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit, AfterViewInit {
	selectedConditions: Array<{
		text: any
		value: any
		defaultValue: any
		type: any
		show?: boolean
		formType?: any
		options?: any
		multiple?: boolean
		enableTime?: false
		dateFormat?: string
		minDate?: string
		mode?: string | null | undefined
		ignoreTimeZone?: boolean
		operator?: string
	}> = [{ text: '', type: '', value: '', defaultValue: '', formType: 'text' }]

	timezone = getTimeZone()
	@Input() key: string
	@Input() isLoading: boolean
	@Input() searchSelection: Record<any, any>
	@Input() firstTimeLoad: boolean = false

	@Output() search: EventEmitter<any> = new EventEmitter<any>()
	queryParams: any = {}
	constructor(
		private cdr: ChangeDetectorRef,
		private translateService: TranslateService,
		private datePipe: DatePipe,
		private tabStore: TabStore,
		public toastService: ToastService,
		private route: ActivatedRoute
	) {
		this.route.queryParams.subscribe((params) => {
			for (let i in params) {
				this.queryParams[i] = params[i]
			}
		})

		this.tabStore.params$.pipe(distinctUntilChanged()).subscribe((params) => {
			this.getDefaultSelectedCondition(false, params)
		})
	}

	ngOnInit() {
		// this.storeDefaultFilter()
	}

	ngAfterViewInit() {
		if (this.firstTimeLoad) {
			this.route.queryParams.subscribe((params) => {
				if (Object.keys(params).length) {
					this.getDefaultSelectedCondition()
					this.search.emit(params)
					this.tabStore.saveParams(params)
				} else {
					this.getDefaultSelectedCondition()
					let params = cloneDeep(this.params)
					this.search.emit(params)
				}
			})
		} else {
			this.getDefaultSelectedCondition()
		}
	}

	baseKey: string = 'advanced-search-settings'

	triggerSearch(e: any) {
		e.preventDefault()

		let params = cloneDeep(this.params)

		this.search.emit(params)
		this.tabStore.saveParams(params)
	}

	triggerReset() {
		this.getDefaultSelectedCondition(true)
	}

	getDefaultSelectedCondition(needReset: boolean = false, saveParams: any = {}) {
		let selections = cloneDeep(this.filterSelection)

		let params = isEmpty(saveParams) ? this.queryParams : saveParams

		this.selectedConditions = []

		let allSettings = this.getSelectedConditions()

		let saveData: any = allSettings[this.key]

		if (!isEmpty(saveData)) {
			this.selectedConditions = saveData
		}

		if (needReset) {
			this.selectedConditions = []
		} else {
			selections.forEach((select: any) => {
				if (params[select.value]) {
					let value = params[select.value]

					let match = value.match(/(<=|>=|<|>|=|between)(.*)/)
					if (match) {
						let operator = match[1]
						value = value.replace(operator, '')

						select.operator = operator
						if (operator == 'between') {
							select.mode = 'range'
						}
					}

					select.show = true
					select.defaultValue = value
				}
			})
		}

		if (selections.filter((v: any) => v.show).length) {
			selections
				.filter((v: any) => v.show)
				.forEach((v: any, k: any) => {
					this.selectedConditions[k] = {
						...v,
						type: v.value,
						operator: v.operator,
						formType: v.formType,
						value: v?.defaultValue || '',
						show: v.show,
					}

					// this.selectFilter(v.value)
					this.cdr.detectChanges()
				})
		} else {
			this.addCondition()
		}
	}

	get params() {
		return this.selectedConditions
			.filter((v: any) => !isEmpty(v.value) && !isEmpty(v.type))
			.reduce((current: any, next: any) => {
				let value = next.value

				if (next.formType == 'date') {
					if (value.includes('to')) {
						let dateData = value.split('to')

						let date1, date2
						if (next?.ignoreTimeZone) {
							date1 = dateData[0]
							date2 = dateData[1]
						} else {
							date1 = moment.utc(dateData[0].trim()).subtract(this.timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
							date2 = moment.utc(dateData[1].trim()).subtract(this.timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}

						value = `${date1} to ${date2}`
					} else {
						if (isDateTimeFormat(value)) {
							if (next?.ignoreTimeZone) {
								// value = value
							} else {
								value = moment.utc(value).subtract(this.timezone, 'hours').format('YYYY-MM-DD HH:mm:ss')
							}
						}
					}
				}

				if (next?.operator) {
					value = `${next.operator}${value}`
				}

				if (Array.isArray(next.value)) {
					value = next.value.join(',')
				}
				current[next.type] = value
				return current
			}, {})
	}

	get filterSelection() {
		let selections: any = []
		if (this.searchSelection) {
			selections = [
				{
					text: this.translateService.instant('MENU.REGISTER.GENERAL.SELECTATYPE'),
					value: null,
				},
				...this.searchSelection.headers.filter((v: any) => {
					return v?.filter !== false
				}),
				...(this.searchSelection?.extra ? this.searchSelection.extra : []),
			]
		}

		return selections
	}

	conditions(index: number): Array<{ value: any; text: any }> {
		let selectedKeys: any = []
		for (let selectedCondition of this.selectedConditions) {
			if (selectedCondition.type) {
				selectedKeys.push(selectedCondition.type)
			}
		}

		return this.filterSelection.filter(
			(v: any) => !selectedKeys.includes(v.value) || this.selectedConditions[index].type == v.value
		)
	}

	removeCondition(index: any) {
		if (this.selectedConditions[index].value) {
			this.selectedConditions[index].value = ''
			return
		}
		if (this.selectedConditions.length == 1) {
			this.selectedConditions[0].text = ''
			this.selectedConditions[0].type = ''
			this.selectedConditions[0].value = ''
			this.selectedConditions[0].formType = undefined
			this.selectedConditions[0].options = undefined
			return
		} else {
			this.selectedConditions.splice(index, 1)
		}
	}
	addCondition() {
		if (this.isDisabled()) {
			return
		}
		this.selectedConditions.push({
			text: '',
			type: '',
			value: '',
			defaultValue: '',
			formType: 'text',
		})
	}

	selectFilter(type: any) {
		let match = this.filterSelection.find((v: any) => v.value === type)

		let selected: any = this.selectedConditions.find((item: any) => item.type == type)

		// selected.value = null

		if (match) {
			if (match?.enableTime) {
				selected.enableTime = match.enableTime
			}
			if (match?.mode) {
				selected.mode = match.mode == 'default' ? '' : match.mode
			}

			if (match.formType == 'number') {
				selected.operator = '='
			} else {
				selected.operator = undefined
			}

			if (match.formType == 'date') {
				selected.operator = match?.operator || 'between'
				if (selected.operator == 'between') {
					selected.mode = 'range'
				}
			}

			if (match?.dateFormat) {
				selected.dateFormat = match.dateFormat
			}

			if (match?.multiple) {
				selected.multiple = match.multiple
			}

			if (match?.show) {
				selected.show = match.show
			}

			if (match?.ignoreTimeZone) {
				selected.ignoreTimeZone = match.ignoreTimeZone
			}

			selected.formType = match.formType
			selected.text = match.text
			if (selected.formType == 'select') {
				selected.options = match.options
			}

			if (match?.minDate) {
				selected.minDate = this.datePipe.transform(match.minDate, 'yyyy-MM-dd') ?? undefined
			}
		}
	}

	getSelectedConditions() {
		let allSettings: any = localStorage.getItem(this.baseKey)
		if (allSettings) {
			try {
				allSettings = JSON.parse(allSettings)
			} catch (e) {
				allSettings = {}
			}
		} else {
			allSettings = {}
		}

		return allSettings
	}

	saveSelectedConditions(toast: boolean = true) {
		let saveData = this.selectedConditions.filter((v) => v.type)
		let allSettings: any = this.getSelectedConditions()
		allSettings[this.key] = saveData

		localStorage.setItem(this.baseKey, JSON.stringify(allSettings))

		if (toast) {
			this.toastService.show('Your setting is saved', {
				delay: 2000,
				autohide: true,
				headertext: 'System Reminder',
			})
		}
	}

	isDisabled(): boolean {
		if (!this.searchSelection?.headers) {
			return false
		}

		return this.selectedConditions.length == this.filterSelection.length - 1
	}

	validateNumberInput(event: any) {
		event.target.value = formatPrice(event.target.value)
	}

	onOperatorChange(event: any, index: any) {
		let operator = event.target.value

		this.selectedConditions[index].value = null
		this.selectedConditions[index].mode = ''

		if (operator == 'between') {
			this.selectedConditions[index].mode = 'range'
		}
	}

	copyParams = () => {
		let value: string = ''

		let params = cloneDeep(this.params)

		let url = window.location.protocol + '//' + window.location.host + window.location.pathname

		if (!isEmpty(params)) {
			let query = Object.keys(params)
				.map((key) => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
				})
				.join('&')

			url += '?' + query
		}

		value = url

		navigator.clipboard.writeText(value).catch(() => {
			console.error('Unable to copy text')
		})

		this.toastService.show('Url is copied', {
			delay: 2000,
			autohide: true,
			headertext: 'System Reminder',
		})
	}
}
