import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PreventClickjackingGuard } from '@guards/preventClickJack.guard'
import { getPlatform } from '@helper/helper'
import { AuthGuard } from './helper/auth.guard'

let module: any
let platform: any
platform = getPlatform()

module = import('@components/auth/auth.module').then((m) => m.AuthModule)
if (platform == 'dealer') {
	module = import('@dealerComponents/auth/auth.module').then((m) => m.AuthModule)
}

export const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => module,
		canActivate: [PreventClickjackingGuard],
	},
	{
		path: 'callback',
		loadChildren: () => import('./dealer-components/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [PreventClickjackingGuard],
	},
	{
		path: 'error',
		loadChildren: () => import('./components/errors/errors.module').then((m) => m.ErrorsModule),
		canActivate: [PreventClickjackingGuard],
	},
	{
		path: '',
		canActivate: [AuthGuard, PreventClickjackingGuard],
		loadChildren: () => import('@shared/layout/layout.module').then((m) => m.LayoutModule),
	},
	{ path: '**', redirectTo: 'error/404' },
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
