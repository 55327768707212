<div clickOutside (clickOutside)="clickedOutside()">
	<button
		type="button"
		class="btn btn-sm btn-secondary w-100 text-start d-flex align-items-center"
		(click)="showMemberList()">
		<span class="bi bi-people me-3 fs-3"></span>
		<span>Members</span>
	</button>

	<div *ngIf="showDropdown" class="dropdown-list">
		<div class="d-flex align-items-center justify-content-center mb-4 p-5 pb-0">Members</div>
		<div class="mb-4 px-5">
			<input
				type="text"
				class="form-control"
				placeholder="Search members"
				(input)="onSearchChange($event.target.value)" />
		</div>
		<div
			[ngClass]="{
            'overlay overlay-block min-h-200px': isLoading$ | async,
        }">
			<div class="overlay-layer card-rounded d-flex flex-column" *ngIf="isLoading$ | async">
				<div class="spinner-border text-black" role="status"></div>
			</div>
			<div class="mb-6">
				<label class="fs-5 w-100 px-5 text-gray-600 fw-semibold mb-2">All roles</label>
				<div class="d-flex align-items-center gap-4 px-4 flex-wrap">
					<div *ngFor="let role of roleDataSubject.value">
						<button
							type="button"
							(click)="selectRole(role)"
							class="btn-role cursor-pointer d-flex align-items-center flex-stack gap-3"
							[ngClass]="{
								selected: checkSelectRole(role)
							}">
							<span class="fs-7 fw-semibold">
								{{ role.name }}
							</span>
							<span class="bi bi-check2" *ngIf="checkSelectRole(role)"> </span>
						</button>
					</div>
				</div>
			</div>
			<div class="mb-6">
				<label class="fs-5 w-100 px-5 text-gray-600 fw-semibold mb-2">All members</label>

				<div *ngIf="!(isLoading | async)">
					<div class="d-flex flex-column members">
						<div
							*ngFor="let member of filteredMembers"
							class="cursor-pointer user-element fs-5 fw-semibold px-5 py-2 d-flex align-items-center flex-stack gap-3"
							[ngClass]="{
								selected: checkSelect(member)
							}"
							(click)="selectMemberEvent(member)">
							<span class="text-trancate-1">
								{{ member.name }}
							</span>
							<span class="bi bi-check2" *ngIf="checkSelect(member)"> </span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
