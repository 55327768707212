import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { TranslationService } from '@assets/i18n'
import { TaskService } from '@services/task.service'
import { CookieService } from 'ngx-cookie-service'
import { UserActivityService } from './services/user/userActivity.service'
// language list
import { Router } from '@angular/router'
import en from '@assets/i18n/vocabs/en'
import zh from '@assets/i18n/vocabs/zh'
import { getPlatform, isEmpty } from '@helper/helper'
import { AuthService } from '@services/auth.service'
import { ModalService } from '@services/modal.service'
import { ThemeModeService } from '@shared/partials/layout/theme-mode-switcher/theme-mode.service'
import { ToastService } from '@shared/toast/toast.service'
import { Subscription } from 'rxjs'

@Component({
	// tslint:disable-next-line:component-selector
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'body[root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	showTaskModal: boolean = false
	taskData: any = {}
	getPlatform = getPlatform
	isOpenCompanyModal: boolean = false
	isEmpty = isEmpty
	me: any

	private modalSubscription: Subscription

	constructor(
		private translationService: TranslationService,
		private modeService: ThemeModeService,
		private modalService: ModalService,
		private cdr: ChangeDetectorRef,
		private taskService: TaskService,
		private userActivityService: UserActivityService,
		public authService: AuthService,
		private router: Router,
		private cookieService: CookieService,
		private toastService: ToastService
	) {
		this.authService.currentUser$.subscribe((res) => {
			this.me = res
		})

		this.translationService.loadTranslations({
			en: en,
			zh: zh,
		})

		this.taskService.isOpenModal$.subscribe((result) => {
			this.showTaskModal = result
			// this.cdr.detectChanges()
		})
	}

	openCompanyApplication() {
		this.isOpenCompanyModal = true
		this.cdr.detectChanges()
	}

	closeModal(e: any) {
		this.isOpenCompanyModal = false
		this.cdr.detectChanges()
	}

	ngOnInit() {
		this.modeService.init()
		this.userActivityService.startMonitoring()
	}

	ngOnDestroy() {
		this.userActivityService.stopMonitoring()
	}
}
