import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'
import { isEmpty } from '@helper/helper'
import { AuthService } from '@services/auth.service'
import { AdminService } from '@services/setting/admin.service'
import { RoleService } from '@services/setting/role.service'
import { BehaviorSubject, forkJoin } from 'rxjs'

@Component({
	selector: 'app-task-member',
	styleUrls: ['./member.component.scss'],
	templateUrl: './member.component.html',
})
export class TaskMemberComponent implements OnInit, AfterViewInit, OnChanges {
	constructor(
		private cdr: ChangeDetectorRef,
		private adminService: AdminService,
		private authService: AuthService,
		private roleService: RoleService
	) {}

	ngOnInit(): void {
		this.reloadData()
	}

	@Input() selectedMembers: any

	@Output() selectMember: EventEmitter<any> = new EventEmitter<any>()
	@Output() unSelectMember: EventEmitter<any> = new EventEmitter<any>()
	@Output() updateSelectedRoles: EventEmitter<any> = new EventEmitter<any>()

	isLoading$ = this.adminService.isLoading$

	ngOnChanges(changes: SimpleChanges): void {}

	ngAfterViewInit(): void {}

	me = this.authService.getAuthFromLocalStorage()

	showDropdown: boolean = false

	selectedRoles: any = []
	members: any = []

	dataSubject = new BehaviorSubject<any>([])
	roleDataSubject = new BehaviorSubject<any>([])
	searchText = ''

	showMemberList() {
		this.showDropdown = !this.showDropdown
		this.cdr.detectChanges()
	}

	clickedOutside(): void {
		this.searchText = ''
		this.showDropdown = false
		this.cdr.detectChanges()
	}

	getAdminList() {
		let services = []

		services.push(
			this.roleService.getRoleListData({
				params: { limit: 99999, sortKey: 'name', sortValue: 'asc', task: true },
			})
		)
		services.push(
			this.adminService.getAdminListData({
				params: { limit: 99999, sortKey: 'name', sortValue: 'asc', task: true, enabled: 1 },
			})
		)

		forkJoin(services).subscribe({
			next: ([resultRole, resultAdmin]) => {
				let { data: roles } = resultRole.data.roles
				this.roleDataSubject.next(roles)

				let { admins } = resultAdmin.data
				let data = admins.data.filter((admin: any) => admin.uuid !== this.me.uuid)
				data.unshift(this.me)
				this.dataSubject.next(data)
				this.members = data
				this.cdr.detectChanges()
			},
		})
	}

	reloadData(clearData: boolean = false) {
		if (clearData) {
			this.dataSubject.next([])
		}

		if (isEmpty(this.dataSubject.value)) {
			this.getAdminList()
		}
	}

	onSearchChange(searchValue: string) {
		this.searchText = searchValue
	}

	get filteredMembers() {
		return this.dataSubject.value.filter((member: any) =>
			member.name.toLowerCase().includes(this.searchText.toLowerCase())
		)
	}

	selectRole(role: any) {
		let selected = this.selectedRoles.find((v: any) => v.name == role.name)
		let members = this.dataSubject.value.filter((member: any) => member.roles.find((r: any) => r.name == role.name))

		if (selected) {
			let index = this.selectedRoles.findIndex((v: any) => v.name == role.name)
			if (index > -1) {
				this.selectedRoles.splice(index, 1)

				let members = this.selectedMembers.filter(
					(member: any) =>
						!member.roles.find((r: any) => this.selectedRoles.map((ros: any) => ros.name).includes(r.name))
				)

				if (members.length) {
					members.forEach((member: any) => {
						this.unSelectMember.emit(member)
					})
				}
			}
		} else {
			if (members.length) {
				members.forEach((member: any) => {
					this.selectMember.emit(member)
				})
			}

			this.selectedRoles.push(role)
		}
	}

	selectMemberEvent(member: any) {
		let selected = this.selectedMembers.find((v: any) => v.uuid == member.uuid)
		if (selected) {
			this.unSelectMember.emit(member)
		} else {
			this.selectMember.emit(member)
		}
	}

	checkSelectRole(role: any): boolean {
		let selected = this.selectedRoles.find((v: any) => v.name == role.name)

		if (selected) {
			return true
		}
		return false
	}

	checkSelect(member: any): boolean {
		let selected = this.selectedMembers.find((v: any) => v.uuid == member.uuid)

		if (selected) {
			return true
		}
		return false
	}
}
