<div class="page-title">
	<div class="card shadow-sm">
		<div class="card-body">
			<div class="d-flex flex-column justify-content-center flex-wrap align-items-center p-12 pb-0">
				<i class="bi bi-sign-stop" [style.font-size]="'250px'"></i>
				<div class="mt-10 fw-bold" [style.font-size]="'2.5rem'">Permission Denied</div>
				<div class="mt-10" [style.font-size]="'2rem'">
					The page or action trying to execute has restricted access.
				</div>
				<div class="mt-2" [style.font-size]="'2rem'">Please refer to your system administrator</div>
			</div>
		</div>
	</div>
</div>
