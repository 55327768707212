import { environment } from '@env/environment'
import { isEmpty } from 'lodash'

let updateKey = 'isShariotUpdatedV6'

let item = localStorage.getItem(updateKey)

if (isEmpty(item)) {
	let user = localStorage.getItem(environment.USERDATA_KEY)
	user = JSON.parse(user)

	const url = `${environment.apiUrl}logout`

	const token = user?.bearerToken || ''

	fetch(url, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}
			return response.json()
		})
		.then((data) => {
			localStorage.clear()
			sessionStorage.clear()
			clearAllCookies()

			localStorage.setItem(updateKey, 'true')
		})
		.catch((error) => {
			console.error('Fetch error:', error)
		})
}

function clearAllCookies() {
	var cookies = document.cookie.split('; ')
	for (var c = 0; c < cookies.length; c++) {
		var d = window.location.hostname.split('.')
		while (d.length > 0) {
			var cookieBase =
				encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
				'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
				d.join('.') +
				' ;path='
			var p = location.pathname.split('/')
			document.cookie = cookieBase + '/'
			while (p.length > 0) {
				document.cookie = cookieBase + p.join('/')
				p.pop()
			}
			d.shift()
		}
	}
}
