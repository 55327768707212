import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { finalize } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class ReportUserService {
	constructor(private http: HttpClient) {}
	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public isLoading$ = this.isLoadingSubject.asObservable()

	getUserData = (params: any = {}): Observable<any> => {
		if (this.isLoadingSubject.value) {
			return of(null)
		}
		this.isLoadingSubject.next(true)
		const url = `reports/users`
		return this.http.get(url, params).pipe(finalize(() => this.isLoadingSubject.next(false)))
	}
}
