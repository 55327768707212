import { Component, OnDestroy, TemplateRef } from '@angular/core'
import { ToastService } from './toast.service'

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastComponent implements OnDestroy {
	constructor(public toastService: ToastService) {}

	ngOnDestroy(): void {
		this.toastService.clear()
	}

	isTemplate(toast: any) {
		return toast.textOrTpl instanceof TemplateRef
	}
}
