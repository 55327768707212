<div class="input-group">
	<span class="input-group-text bg-white">
		<span class="bi bi-calendar-event"></span>
	</span>
	<input
		class="form-control custom-date-select"
		#dateContent
		mode="range"
		name="dob"
		type="text"
		[disabled]="isLoading"
		[(ngModel)]="selectedDate.value"
		(change)="onDateChange($event, true)"
		mwlFlatpickr />
	<div class="input-group-text cursor-pointer bg-white p-0 m-0" clickOutside (clickOutside)="clickedOutside()">
		<div>
			<div (click)="toggleMenu($event)" class="p-1">
				<button class="btn btn-sm">
					<ng-container *ngIf="!selectedDate?.type">
						<span class="bi bi-three-dots-vertical"> </span>
					</ng-container>
					<ng-container *ngIf="selectedDate?.value && selectedDate?.type">
						<span *ngIf="selectedDate.type != 'MONTH_YEAR' && selectedDate.type != 'YEAR'">
							{{ 'DASHBOARD.DATETYPE.' + selectedDate?.type | translate }}
						</span>
						<span *ngIf="selectedDate.type == 'MONTH_YEAR'">
							{{
								'DASHBOARD.DATETYPE.' + selectedDate?.type
									| translate
										: {
												month: 'MONTH.' + selectedMonth | translate,
												year: selectedYear
										  }
							}}
						</span>
						<span *ngIf="selectedDate.type == 'YEAR'">
							{{ selectedYear }}
						</span>
					</ng-container>
				</button>
			</div>
			<div class="menu" [class.active]="isMenuOpened">
				<div class="menu-item" [class.active]="selectedDate.type === 'TODAY'" (click)="selectDate('TODAY')">Today</div>
				<div class="menu-item" [class.active]="selectedDate.type === 'YESTERDAY'" (click)="selectDate('YESTERDAY')">
					Yesterday
				</div>

				<div class="menu-item" [class.active]="selectedDate.type === 'THIS_WEEK'" (click)="selectDate('THIS_WEEK')">
					This Week
				</div>

				<div class="menu-item" [class.active]="selectedDate.type === 'LAST_WEEK'" (click)="selectDate('LAST_WEEK')">
					Last Week
				</div>

				<!-- <div
                class="menu-item"
                [class.active]="selectedDate.type === 'LAST_7_DAYS'"
                (click)="selectDate('LAST_7_DAYS')">
                Last 7 days
            </div>
            <div
                class="menu-item"
                [class.active]="selectedDate.type === 'LAST_30_DAYS'"
                (click)="selectDate('LAST_30_DAYS')">
                Last 30 days
            </div> -->
				<div class="menu-item" [class.active]="selectedDate.type === 'THIS_MONTH'" (click)="selectDate('THIS_MONTH')">
					This Month
				</div>
				<div class="menu-item" [class.active]="selectedDate.type === 'LAST_MONTH'" (click)="selectDate('LAST_MONTH')">
					Last Month
				</div>
				<div class="menu-item" [class.active]="selectedDate.type === 'THIS_YEAR'" (click)="selectDate('THIS_YEAR')">
					This Year
				</div>
				<div class="menu-item" [class.active]="selectedDate.type === 'LAST_YEAR'" (click)="selectDate('LAST_YEAR')">
					Last Year
				</div>
				<div class="separator"></div>
				<div class="menu-item" [class.active]="selectedDate.type === 'MONTH_YEAR' || selectedDate.type === 'YEAR'">
					<div class="d-flex align-items-center">
						<div>
							<select
								class="form-select border-top-right-radius-none border-bottom-right-radius-none"
								[(ngModel)]="selectedMonth"
								(change)="onMonthChange($event)">
								<option [value]="null" disabled>Please select a month</option>
								<option *ngFor="let month of months" [value]="month">
									{{ 'MONTH.' + month | translate }}
								</option>
							</select>
						</div>
						<div>
							<select
								class="form-select border-top-left-radius-none border-bottom-left-radius-none"
								[(ngModel)]="selectedYear"
								(change)="onYearChange($event)">
								<option *ngFor="let year of years" [value]="year">{{ year }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
