import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { clearErrors, setErrors, validateForm } from '@helper/helper'
import { CustomFormControlService } from '@services/custom-form-control.service'
import { FormService } from '@services/form.service'

@Component({
	selector: 'app-custom-form-group',
	templateUrl: './form-group.component.html',
})
export class CustomFormGroup implements OnInit, OnDestroy {
	uuid: string = this.generateUUID()
	constructor(
		private fb: FormBuilder,
		private customFormControlService: CustomFormControlService,
		private formService: FormService,
		private cdr: ChangeDetectorRef
	) {}

	@Input() formGroup: FormGroup
	@Output() triggerSubmit: EventEmitter<any> = new EventEmitter<any>()

	ngOnInit(): void {
		this.formService.initialize(this)
	}

	ngOnDestroy(): void {
		this.formService.unsubscribe(this.uuid)
	}

	clearErrors() {
		clearErrors(this.formGroup)
	}

	validate(): any {
		return validateForm(this.formGroup, this.customFormControlService)
	}

	reset(): void {
		this.formGroup.reset()
		clearErrors(this.formGroup)
	}

	setErrors = (error: any) => {
		setErrors(error, this.formGroup)
	}

	setValid = () => {
		this.formGroup.setValue({
			...this.formGroup.value,
		})
	}

	submit() {
		this.validate()

		if (this.formGroup.invalid) {
			return
		}

		this.triggerSubmit.emit()
	}

	generateUUID() {
		// Public Domain/MIT
		var d = new Date().getTime() //Timestamp
		var d2 = (performance && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 //random number between 0 and 16
			if (d > 0) {
				//Use timestamp until depleted
				r = (d + r) % 16 | 0
				d = Math.floor(d / 16)
			} else {
				//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0
				d2 = Math.floor(d2 / 16)
			}
			return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
		})
	}
}
