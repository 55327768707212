import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SharedModule } from '@shared/shared.module'
import { TaskMemberComponent } from '@shared/task/member.component'
import { CookieService } from 'ngx-cookie-service'

import { TaskModalComponent } from '@shared/task/create.component'

import { Router } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '@env/environment'
import { getCookie, getQueryParams, isEmpty } from '@helper/helper'
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { HttpInterceptor } from '@services/http.service'
import { ToastComponent } from '@shared/toast/toast.component'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { ClipboardModule } from 'ngx-clipboard'
import { NgxEchartsModule } from 'ngx-echarts'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthService } from './services/auth.service'

function appInitializer(authService: AuthService, router: Router) {
	return () => {
		return new Promise(async (resolve) => {
			let params: any = {}

			let redirectToken = getCookie('redirectToken')
			if (environment.env == 'local') {
				redirectToken = getQueryParams('redirectToken')
			}

			if (!isEmpty(redirectToken)) {
				params = { redirectToken: redirectToken }
			}

			//@ts-ignore

			try {
				await authService.getUserByToken(params)
			} catch (e) {}

			let user = authService.getAuthFromLocalStorage()

			let toPage: any = window.location.pathname

			if (user) {
				if (!user.is_enable_mfa && !toPage.includes('google2fa')) {
					location.href = '/auth/google2fa'
				}

				if (user.is_enable_mfa) {
					toPage = toPage.split('/')

					if (toPage[1] == 'auth' && toPage.length > 1) {
						location.href = '/' + window.location.search
					}
				}
			} else {
				let allowPages = [
					'/auth/login',
					'/auth/registration',
					'/auth/forgot-password',
					'/auth/reset-password',
					'/callback',
				]

				if (!allowPages.includes(toPage)) {
					location.href = '/auth/login' + window.location.search
				}
			}

			resolve(true)
		})
	}
}

@NgModule({
	declarations: [ToastComponent, AppComponent, TaskModalComponent, TaskMemberComponent],
	exports: [ToastComponent, TaskMemberComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot(),
		ClipboardModule,
		AppRoutingModule,
		InlineSVGModule.forRoot(),
		NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
		NgbModule,
		NgbTooltipModule,
		SharedModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	providers: [
		CookieService,
		{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializer,
			multi: true,
			deps: [AuthService],
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {}
