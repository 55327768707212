import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { getStatusBadge } from '@helper/helper'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { AuthService } from '@services/auth.service'
import { ModalService } from '@services/modal.service'
import { TaskService } from '@services/task.service'

@Component({
	selector: 'app-task-list-modal',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class TaskListModalComponent implements OnInit, AfterViewInit {
	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>()

	@ViewChild('content') content: any

	private modalRef: NgbModalRef

	data: any = {}
	getStatusBadge = getStatusBadge

	isLoading$ = this.taskService.isLoading$

	tasks: any = []

	constructor(
		private ngbModalService: NgbModal,
		private fb: FormBuilder,
		private modalService: ModalService,
		private authService: AuthService,
		private taskService: TaskService
	) {
		// this.taskService.data$.subscribe((data) => {
		// 	this.data = data
		// })
	}

	selectedTab: string = 'new'

	async ngOnInit() {
		await this.load()
	}

	async ngAfterViewInit() {
		await this.open()
	}

	form: FormGroup
	selectedMembers: any = []
	isJoined: boolean = false
	me = this.authService.getAuthFromLocalStorage()

	open(): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.modalRef = this.ngbModalService.open(this.content, {
				size: 'xl',
				backdrop: 'static',
				keyboard: false,
			})
			this.modalRef.result.then(resolve, (reason: any) => {
				this.close({})
			})
		})
	}

	async load() {
		this.taskService.getTaskList({ params: { ...this.data, status: this.selectedTab } }).subscribe({
			next: (res: any) => {
				let { tasks } = res.data
				this.tasks = tasks.data
			},
		})
	}

	async close(data: any = {}): Promise<void> {
		this.modalRef.close()
		this.modalService.close()
		this.closeModal.emit(data)
	}

	avatarClass(index: number): string {
		index = index % 5
		let colors: any = {
			0: 'bg-light-primary text-primary',
			1: 'bg-light-warning text-warning',
			2: 'bg-light-danger text-danger',
			3: 'bg-light-info text-info',
			4: 'bg-light-success text-success',
		}

		return colors[index]
	}

	openTask(task: any) {
		this.close({})
		this.taskService.close()
		// this.taskService.syncData({
		// 	...this.data,
		// 	task: task,
		// })
		this.taskService.open()
	}

	checkIsSelected(tab: string) {
		return this.selectedTab === tab
	}

	selectTab(tab: string) {
		this.selectedTab = tab
		this.load()
	}
}
