import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'
import { isEmpty } from './helper'

export const minCheck = (min = 1): ValidatorFn => {
	return (control: AbstractControl): { [key: string]: any } | null => {
		if (!(control && control instanceof FormArray)) {
			return null
		}

		const totalChecked = control.controls
			.map((ctrl) => ctrl.value)
			.reduce((prev, next) => (next ? prev + next : prev), 0)

		return totalChecked >= min ? null : { minCheck: { totalChecked: totalChecked, required: min } }
	}
}

export function isNumber(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const isValid = !isNaN(Number(control.value))
		return isValid ? null : { isNumber: true }
	}
}

export function postcode(length: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const isValid = /^[0-9]{length}$/.test(control.value)
		if (!isValid) {
			return { sgPostcode: true }
		}
		return null
	}
}

export function url(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const pattern =
			/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|http:\/\/localhost:|https:\/\/localhost:)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*(\.localhost|[a-z]{2,5})?(:[0-9]{1,5})?(\/.*)?$/i

		const isValid = pattern.test(control.value)

		if (!control.value) {
			return { url: true }
		}

		if (!isValid) {
			return { url: true }
		}
		return null
	}
}

export function price(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const validPriceRegex = /^\d+(\.\d{1,2})?$/

		const value = control.value

		if (isEmpty(value)) {
			return null
		}

		const isValid = validPriceRegex.test(value)

		return isValid ? null : { price: true }
	}
}

export function alphanumeric(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const forbidden = /[^a-zA-Z0-9]/.test(control.value)
		return forbidden ? { alphanumeric: { value: control.value } } : null
	}
}

export function isMatchWith(otherFieldName: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const otherField = control.parent?.get(otherFieldName)
		if (otherField && otherField.value !== control.value) {
			return { mismatch: true, target: otherFieldName }
		}
		return null
	}
}

export function noScriptTag(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } | null => {
		const forbidden = /<(.|\n)*?>/i.test(control.value)
		return forbidden ? { noScriptTag: { value: control.value } } : null
	}
}

export function atLeast(min: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const group = control as FormGroup
		const values = Object.values(group.controls)
		const checkedCount = values.filter((control) => control.value).length
		return checkedCount >= min ? null : { atLeast: true, value: min }
	}
}
